'use client';
import { Icon } from '@bayada/shared/ui-components';
import { FormControl, TextField } from '@mui/material';
import { useAppContext } from 'apps/bayada/app/context/app-context';
import { useRouter } from 'next/navigation';
import React, { ChangeEvent, useEffect, useState } from 'react';

export interface SiteSearchInputFieldProps {
  className?: string;
  width?: string;
  handleVisibilityOnSubmit?: (flag: boolean) => void;
}

export const SiteSearchInputField = (props: SiteSearchInputFieldProps) => {
  const { push } = useRouter();
  const { className = '', width = '100%' } = props || {};
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { context } = useAppContext();
  const { userAgent } = context || {};

  useEffect(() => {
    return () => {
      setSearchQuery('');
    };
  }, []);

  const searchOnChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event?.target?.value;
    setSearchQuery(value);
  };

  const searchOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    navigateToSearchResults();
  };

  const navigateToSearchResults = () => {
    setTimeout(() => {
      if (props?.handleVisibilityOnSubmit) {
        props?.handleVisibilityOnSubmit(false);
      }
    }, 300);
    if (userAgent === 'Firefox') {
      window.location.href = `/search?q=${searchQuery}`;
    } else {
      push(`/search?q=${searchQuery}`, {});
    }
  };

  return (
    <div className={`${className ?? 'defualt-search-box'}`}>
      <form
        onSubmit={(e) => searchOnSubmit(e)}
        className={`flex mx-auto lg:w-[${width}] w-full items-center  px-2 py-1`}
      >
        <FormControl
          className={`icon-text-field icon-right standardInputField relative flex items-center rounded-md`}
        >
          <div
            className="search-icon right-0 absolute top-0 z-10 flex h-full items-center justify-center"
            onClick={() => navigateToSearchResults()}
            role="button"
            tabIndex={0}
            aria-label="icon"
          >
            <Icon
              iconName="search"
              className="svg-icon icon-24 mx-4 flex items-center justify-center"
            ></Icon>
          </div>
          <TextField
            id="input-with-icon-textfield2"
            label="Search"
            variant="standard"
            className="app-icon-field"
            value={searchQuery}
            onChange={(e) => searchOnChange(e)}
            inputRef={(input) => input && input.focus()}
          />
        </FormControl>
      </form>
    </div>
  );
};
