/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import { Entry, EntrySkeletonType } from 'contentful';
import {
  useContentfulInspectorMode,
  useContentfulLiveUpdates
} from '@contentful/live-preview/react';
import ComponentResolver from '../component-resolver/component-resolver';
import { Suspense, useEffect, useRef, useState } from 'react';
import { pageMap } from 'apps/bayada/mapping';
import { convertObjectToArray } from 'apps/bayada/utils/helper';
import OfficePage from '../office-page/office-page';
import JvPage from '../joint-venture-offices/joint-venture-office-page';
import { ResetScrollRestore } from '@bayada/shared/ui-components';

export interface PageTemplateProps {
  slug: string[];
  data?: Entry<EntrySkeletonType, undefined, string> | undefined | any;
  isDraftMode?: boolean;
}
/* This code defines a functional component called `PageTemplate` in TypeScript React. The component
takes in props of type `PageTemplateProps`, which includes `slug`, `data`, and `isDraftMode`
properties. */

export const PageTemplate = (props: PageTemplateProps) => {
  const { data, slug } = props;
  const mainRef = useRef<HTMLElement | null>(null);
  const [state] = useState(data);
  const updatedState = useContentfulLiveUpdates(state);
  const isLocalOfficePage =
    updatedState?.sys?.contentType?.sys?.id === 'localOfficePage';
  const isJvPage = updatedState?.sys?.contentType?.sys?.id === 'jv';
  const templateReference = updatedState as any;
  const templateReferenceId = templateReference?.sys?.contentType?.sys?.id;
  const templateBlocksObj = templateReference?.fields;
  const templateBlocks = convertObjectToArray(templateBlocksObj);
  const inspectorProperties = useContentfulInspectorMode();
  const isPage = templateReferenceId && templateReferenceId in pageMap;

  useEffect(() => {
    const handleDOMContentLoad = () => {
      if (mainRef.current) {
        setTimeout(() => {
          mainRef?.current?.classList?.remove('is-loading');
        }, 1000);
      }
    };

    document.addEventListener('DOMContentLoaded', handleDOMContentLoad);
    handleDOMContentLoad();
    return () => {
      document.removeEventListener('DOMContentLoaded', handleDOMContentLoad);
    };
  }, [props]);
  /**
   * The `renderTemplateComponent` function returns a JSX element with dynamic properties and class name
   * based on conditions.
   */

  const renderTemplateComponent = () => (
    <div
      {...inspectorProperties({
        entryId: templateReference?.sys?.id,
        fieldId: templateReference?.fields?.internalName ?? data?.fields?.name
      })}
      className={props.isDraftMode ? 'mb-10' : 'section'}
    >
      <ComponentResolver
        wrapperContainer
        className="page-section"
        data={templateReference}
      />
    </div>
  );
  /**
   * The function `renderBlockLevelComponents` maps over `templateBlocks` to render block-level
   * components based on certain conditions.
   */

  const renderBlockLevelComponents = () =>
    templateBlocks.map((section, index) => {
      const isBannerWithForm =
        section?.fields?.findcarecomponentalignment === 'Bottom center edge' &&
        section?.fields?.findCareComponent
          ? true
          : false;

      const sectionId =
        typeof section === 'object' && section?.fields?.internalName
          ? section.fields.internalName.trim().replace(/\s/g, '-')
          : undefined;

      if (typeof section === 'object') {
        return (
          <div
            {...inspectorProperties({
              entryId: section?.sys?.id,
              fieldId: section?.fields?.internalName ?? data?.fields?.name
            })}
            key={`template-block-${index}`}
            className={`section flex items-stretch justify-between min-w-0 flex-col ${props.isDraftMode ? 'mb-12' : ''} ${
              isBannerWithForm ? 'banner-with-form' : ''
            }`}
            id={sectionId}
          >
            <ComponentResolver
              wrapperContainer
              className="page-section"
              data={section}
            />
          </div>
        );
      }
    });

  return (
    <main
      ref={mainRef}
      className="app-page is-loading"
      style={{ minHeight: '100svh' }}
    >
      <ResetScrollRestore />
      {isLocalOfficePage ? (
        <Suspense fallback={<div>OfficePage Loading...</div>}>
          <OfficePage {...data} slug={slug} />
        </Suspense>
      ) : isJvPage ? (
        <Suspense fallback={<div>JvPage Loading...</div>}>
          <JvPage {...data} slug={slug} />
        </Suspense>
      ) : (
        <div className="page-template-wrapper">
          {isPage ? renderTemplateComponent() : renderBlockLevelComponents()}
        </div>
      )}
    </main>
  );
};

export default PageTemplate;
