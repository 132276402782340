/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import {
  GoogleMapApiKey,
  directionUrl,
  OfficeApibaseURL,
  bayadaOfficeLocationTitle
} from 'apps/bayada/constants';
import { ServiceProps } from 'apps/bayada/components/resources/resources-components/services-list';
import { contentfulClient } from 'apps/bayada/config/contentful-client';

/**
 * Fetches office locations based on the provided latitude, longitude, proximity, and optional services.
 * @param {number | (() => number) | undefined} lat - The latitude coordinate of the location.
 * @param {number | (() => number) | undefined} long - The longitude coordinate of the location.
 * @param {string | undefined} proximity - The proximity range for office search.
 * @param {string[] | undefined} services - Optional services to filter the search results.
 * @returns {Promise<any>} - A Promise that resolves with the response data containing office locations.
 */
export const getOfficeLocations = async (
  lat: number | (() => number) | undefined,
  long: number | (() => number) | undefined,
  proximity: string | undefined,
  baseURL?: string
) => {
  const instance = axios.create({
    baseURL: baseURL ? baseURL : OfficeApibaseURL
  });
  if (lat && long) {
    const response = await instance.get(
      `/v1/OfficeData/${lat}/${long}/${proximity}`
    );
    if (response?.data?.ResponseCode !== 200) {
      throw new Error('Network response was not ok');
    }
    return response?.data;
  }
};

export const getServiceArray = (
  office: any,
  serviceList?: ServiceProps[] | null,
  currentService?: string
) => {
  const servArr: any[] = [];
  office?.services?.forEach((ser: string) => {
    const reqItem = serviceList?.find((el: ServiceProps) => {
      const shortName = el?.shortName?.replace(/\s+/g, '')?.toLowerCase();
      if (ser && shortName) {
        return ser
          ?.replace(/\s+/g, '')
          ?.toLowerCase()
          .includes(shortName?.toLowerCase());
      }
      return false;
    });
    const duplicateServ = servArr?.some(
      (item) => item.masterDataId === reqItem?.masterDataId
    );
    if (reqItem && !duplicateServ) {
      servArr?.push({
        fullName: reqItem?.fullName,
        icon: reqItem?.icon,
        isCurrentService: currentService
          ? reqItem?.fullName?.replace(/[^\w\s]/g, ' ')?.toLowerCase() ===
            currentService?.toLowerCase()
          : false,
        masterDataId: reqItem?.masterDataId
      });
    }
  });
  return servArr;
};

/**
 * Opens the default mail client with a pre-filled email to share office location details.
 * @param {any} officeLoc - The office location object containing details like name, address, phone, etc.
 */
export const shareByMailClick = (officeLoc: any) => {
  const subject = encodeURIComponent(
    `${officeLoc?.name} ${bayadaOfficeLocationTitle}`
  );
  const body = encodeURIComponent(
    `${officeLoc?.name || ''}\n${officeLoc?.mailingAddress1 || ''}\n${officeLoc?.mailingAddress2 || ''}\n${officeLoc?.mailingAddressCity || ''}, ${officeLoc?.mailingAddressState || ''} ${officeLoc?.mailingAddressZipCode || ''}${officeLoc?.phone ? `\nPhone: ${officeLoc.phone.replace(/\D/g, '')?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}` : ''}`
  );
  const mailtoUrl = `mailto:?subject=${subject}&body=${body}`;
  if (window) {
    window.open(mailtoUrl, '_blank');
  }
};

/**
 * Retrieves the latitude and longitude coordinates for a given address using the Google Maps Geocoding API.
 * @param {string | null} address - The address for which to retrieve coordinates.
 * @param {string | undefined} apiKey - The API key for accessing the Google Maps Geocoding API.
 * @returns {Promise<any>} - A Promise that resolves to the response containing the latitude and longitude coordinates.
 */
export const getLocLatLong = async (
  address: string | null | undefined,
  apiKey: string | undefined
) => {
  const res = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`
  );
  return res?.json();
};

/**
 * Opens the default maps app to provide directions from the origin to the specified destination.
 * @param {string} origin - The origin address or coordinates.
 * @param {string} lat - The latitude of the destination.
 * @param {string} lng - The longitude of the destination.
 */
export const goToDirections = (origin: string, lat: string, lng: string) => {
  const directionsUrl = directionUrl
    ?.replace('{origin}', origin)
    ?.replace('{destinationLat}', lat)
    ?.replace('{destinationLng}', lng);
  if (window) {
    window?.open(directionsUrl);
  }
};

/**
 * Opens the default phone app to make a call.
 * @param {string} phone - The phone number to call.
 */
export const handlePhoneClick = (phone: string) => {
  if (window) {
    window.open(`tel:${phone}`, '_self');
  }
};

export const constructRoute = (office: any, service?: string) => {
  const _service = service
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^\w\s]/g, ' ')
    ?.replace(/\s/g, '-');
  const _state = office?.mailingAddressState
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^\w\s]/gi, '')
    ?.replace(/\s+/g, '-');

  const _city = office?.mailingAddressCity
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^\w\s]/gi, '')
    ?.replace(/\s+/g, '-');

  const _street = (
    office?.mailingAddress1?.trim() +
    ' ' +
    office?.mailingAddress2?.trim()
  )
    ?.trim()
    ?.toLowerCase()
    ?.replace(/[^\w\s]/gi, '')
    ?.replace(/\s+/g, '-');
  return `/${_service}/locations/${_state}/${_city}/${_street}/${office?.officeId}`;
};

export const isAutoCompleteFieldValid = (address: string) => {
  if (address?.trim()?.length > 0) {
    return true;
  }
  return false;
};

export const goToFindOffice = async (
  enteredLoc?: string | null,
  selectedAddress?: string,
  selectedService?: string
) => {
  let address;
  if (enteredLoc) {
    const response = await getLocLatLong(enteredLoc, GoogleMapApiKey);
    address = response?.results?.[0]?.formatted_address ?? null;
  } else {
    address = selectedAddress;
  }

  if (address) {
    const findOfficeUrl = `/find-an-office?address=${address?.replace(/[#\\/\s]/g, '+')}&service=${selectedService?.replace(/ /g, '+')}`;
    return findOfficeUrl;
  }
};

/**
 * The function `getOfficeById` fetches office data by ID and handles errors appropriately.
 * @param {string} id - The `id` parameter in the `getOfficeById` function is a string that represents
 * the unique identifier of the office you want to retrieve information for.
 * @returns The function `getOfficeById` is returning the data fetched from the specified API endpoint
 * for the office with the given `id`. If the fetch operation is successful and the response is ok, it
 * returns the JSON data received from the API. If there is an error during the fetch operation or if
 * the response is not ok, it will log the error to the console.
 */
export const getOfficeById = async (id: string) => {
  if (id) {
    try {
      const instance = axios.create({
        baseURL: OfficeApibaseURL
      });
      const officeRes = await instance.get(
        OfficeApibaseURL + `/v1/OfficeData/GetByOfficeId/${id}`
      );
      if (officeRes.data?.ResponseCode !== 200) {
        throw new Error('Network response was not ok');
      }
      const data = await officeRes?.data;

      return data;
    } catch (error) {
      console.log(` ${id} :: getOfficeById error -->`, error);
    }
  } else {
    console.log(`${id} is null --- getOfficeById`);
  }
};

/* The function `getLicenseList` fetches office License Entries from contentful.*/
export const getLicenseList = async () => {
  try {
    const contentful = contentfulClient();
    const licensesListResponse = await contentful?.getEntries({
      content_type: 'officeLicense',
      include: 10
    });
    const data = licensesListResponse?.items.map((list: any) => {
      return list.fields;
    });
    return data;
  } catch (error) {
    console.log('no licenses added');
  }
};

/* This function removes initial and trailing commas and spaces */
export const trimCommasAndSpaces = (text?: string | null) => {
  if (text) {
    return text?.trim()?.replace(/^[,\s]+|[,\s]+$/g, '');
  }
  return '';
};

/* This function removes initial and trailing spaces */
export const trimSpaces = (text?: string | null) => {
  if (text) {
    return text?.trim();
  }
  return '';
};
export const getServicePageSlug = (service?: string) => {
  if (service) {
    const slug = `${service
      ?.toLowerCase()
      ?.replace(/\s+/g, '-')
      ?.replace(/\//g, '&')}`;
    return slug;
  }
};

export const renderOfficeAddress = (office: any) => {
  return (
    (office?.mailingAddress1
      ? trimCommasAndSpaces(office?.mailingAddress1) + ', '
      : '') +
    (office?.mailingAddress2
      ? trimCommasAndSpaces(office?.mailingAddress2) + ', '
      : '') +
    (office?.mailingAddressCity
      ? trimCommasAndSpaces(office?.mailingAddressCity) + ', '
      : '') +
    (office?.mailingAddressState
      ? trimCommasAndSpaces(office?.mailingAddressState) + ', '
      : '') +
    (office?.mailingAddressZipCode
      ? trimCommasAndSpaces(office?.mailingAddressZipCode)
      : '')
  );
};
