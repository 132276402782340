/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import { Container, Grid } from '@mui/material';
import './white-papers.scss';
import { BioPageProps } from 'apps/bayada/components/bio-page/bio-page';
import { CmsRichTextProps } from 'apps/bayada/components/cms-rich-text/cms-rich-text';
import { CmsImageProps } from '@bayada/interfaces';
import { ServiceProps } from '../../resources-components/services-list';
import ArticleTags, { ArticleTag } from '../../resources-components/tags';
import { ShareBlockProps } from 'apps/bayada/components/share-block/share-block';
import DynamicCarousel from 'apps/bayada/components/dynamic-carousel/dynamic-carousel';
import { RightSidebar } from '../../resources-components/resources-right-sidebar';
import {
  PageContent,
  WhitePaperPdfProps
} from '../../resources-components/resources-page-content';
import { useAppContext } from 'apps/bayada/app/context/app-context';
import { useEffect } from 'react';

/* eslint-disable-next-line */
export interface WhitePapersProps {
  internalName: string;
  services: ServiceProps | null;
  thumbnailImage?: CmsImageProps | null;
  title: string;
  overview?: string;
  author: BioPageProps | null;
  date: string;
  image: CmsImageProps | null;
  shareBlock?: ShareBlockProps | null;
  tags?: ArticleTag[];
  contents?: CmsRichTextProps;
  downloadText?: string;
  carouselLabel?: string;
  carouselItemCount?: number;
  aboutTheAuthorLabel?: string;
  whitePaperPdf: WhitePaperPdfProps | null;
  tagsLabel?: string;
  readMoreButtonText?: string;
  readMoreAriaLabel?: string;
  readMoreIcon?: CmsImageProps | null;
  env: string;
}

/**
 * WhitePapers component is the top level component for white paper pages
 * It renders the page content, right sidebar and dynamic carousel
 * The page content is divided into a grid container with 2 columns on large screens and 1 column on smaller screens
 * The right sidebar is hidden on large screens and shown on smaller screens
 * The DynamicCarousel component is used to render the carousel based on the tags and services passed as props
 */
export const WhitePapers: React.FC<WhitePapersProps> = (props) => {
  const { setIsProgressbarRequired } = useAppContext();
  useEffect(() => {
    setIsProgressbarRequired(true);
    return () => {
      setIsProgressbarRequired(false);
    };
  }, []);
  const {
    services,
    internalName,
    title,
    author,
    date,
    image,
    shareBlock,
    contents,
    carouselItemCount = 4,
    carouselLabel,
    downloadText = 'Download',
    aboutTheAuthorLabel = '',
    tags,
    whitePaperPdf,
    tagsLabel
  } = props || {};

  return (
    <div
      role="region"
      aria-label={`${internalName}-container`}
      className="relative pt-10 md:pt-20"
    >
      <Container fixed className="relative mx-auto">
        <Grid container spacing={2}>
          <Grid item lg={9}>
            <PageContent
              title={title}
              shareBlock={shareBlock}
              author={author}
              date={date}
              image={image}
              contents={contents}
              carouselItemCount={carouselItemCount}
              carouselLabel={carouselLabel}
              downloadText={downloadText}
              aboutTheAuthorLabel={aboutTheAuthorLabel}
              whitePaperPdf={whitePaperPdf}
            />
          </Grid>
          <Grid item lg={3} className="hidden lg:block">
            <RightSidebar
              shareBlock={shareBlock}
              tags={tags}
              buttonLabel={downloadText}
              whitePaperPdf={whitePaperPdf}
              tagsLabel={tagsLabel}
            />
          </Grid>
        </Grid>
        {tags && tags?.length > 0 && (
          <div className="col-span-12 lg:col-span-9 order-2 lg:order-1  lg:hidden mb-6">
            <ArticleTags tags={tags} title={tagsLabel} />
          </div>
        )}
      </Container>

      {carouselLabel && (
        <Container fixed className="relative mx-auto mt-20">
          <h3
            className="t-18-21 mb-0 font-frutiger font-bold"
            aria-label="carousel-label"
          >
            {carouselLabel}
          </h3>
        </Container>
      )}

      <DynamicCarousel
        env={props?.env}
        type={'whitepapers'}
        services={[services]}
        tags={tags}
        numberOfSlides={carouselItemCount}
      />
    </div>
  );
};

export default WhitePapers;
