/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import { useCallback, useEffect, useState } from 'react';
import { Container } from '@mui/material';
import React from 'react';
import Banner from '../banner/banner';
import {
  getOfficeLocations,
  trimSpaces
} from 'apps/bayada/app/find-an-office/office-service';
import OfficesList from 'apps/bayada/app/find-an-office/office-components/offices-list';
import OfficeMap from 'apps/bayada/app/find-an-office/office-components/offices-map';
import { GoogleMapContextProvider } from '../../app/context/google-map-context-provider';
import { OfficeDetailsSection } from '../office-page/office-details-section';
import { LocalOfficePageProps } from '../office-page/office-page';
import {
  OfficeModel,
  mapOfficeAPI
} from '../../app/find-an-office/office-data-parser';
import { useAppContext } from 'apps/bayada/app/context/app-context';

/**
/**
 * Renders the Joint-Venture Office Page component.
 *
 * @param {LocalOfficePageProps} props - The props containing data for rendering the Office Page.
 * @returns {JSX.Element} - The rendered Office Page component.
 */
export function JvPage(props: LocalOfficePageProps) {
  const { serviceList, localOfficePage, office, slug } = props || {};
  const slugArr = slug || [];
  const currentOfficeId = Number(slugArr?.[slugArr?.length - 1]);
  const slugService = slugArr[0];
  const proximity = '25';
  const [offices, setOffices] = useState<OfficeModel[]>([]);
  const { context } = useAppContext();
  const OfficeApibaseURL = context?.constants?.OfficeApibaseURL;
  const jvService = serviceList?.find(
    (service) =>
      service?.fullName
        ?.toLowerCase()
        ?.trim()
        ?.replace(/[^\w\s]/g, ' ') ===
      slugService?.toLowerCase()?.replace(/-/g, ' ')
  );

  /**
   * Fetches offices near a given location and updates the state with the fetched offices.
   *
   * @param {any} lat - The latitude of the location.
   * @param {any} long - The longitude of the location.
   * @returns {Promise<void>} - A promise that resolves once the offices are fetched and the state is updated.
   */
  const fetchAllOffices = useCallback(
    async (lat: any, long: any) => {
      if (lat && long) {
        const officeData = await getOfficeLocations(
          lat,
          long,
          proximity,
          OfficeApibaseURL
        );
        const mappedData = officeData?.Response?.map((office: any) => {
          return mapOfficeAPI(office);
        });
        const filteredOffices = mappedData
          ?.filter(Boolean)
          .filter((office: OfficeModel) => {
            return (
              office?.officeId != currentOfficeId &&
              office?.services?.find((service) =>
                service
                  ?.toLowerCase()
                  ?.includes(trimSpaces(jvService?.shortName)?.toLowerCase())
              )
            );
          });
        const nearByOffices = filteredOffices?.slice(0, 4);

        setOffices(nearByOffices?.filter(Boolean));
      }
    },
    [localOfficePage?.service?.fullName]
  );

  useEffect(() => {
    fetchAllOffices(
      office?.mailingAddressLatitude,
      office?.mailingAddressLongitude
    );
  }, [office]);

  return (
    <React.Fragment>
      <div id="office-banner">
        <Banner
          backgroundImage={localOfficePage?.backgroundImage}
          contentAlignment={'center'}
          internalName={''}
          heading={office?.name || ''}
          headingValue="h1"
          subHeading={''}
          text={localOfficePage?.bannerDescription}
          licenseNumber={office?.licenseNumber}
        />
      </div>
      <div className="py-12 md:py-20 w-full">
        {office && (
          <>
            <Container id="office-details-container" fixed className="w-full">
              <OfficeDetailsSection
                office={office}
                officePage={localOfficePage}
              />
            </Container>
            {offices?.length != 0 && (
              <div className="my-10" id="office-maps">
                <GoogleMapContextProvider>
                  <OfficeMap
                    offices={offices}
                    localOfficePage={localOfficePage}
                    serviceList={serviceList}
                    isLocalOfficePage={true}
                    jvService={jvService}
                    isJVPage={office?.isJV}
                  />
                </GoogleMapContextProvider>
              </div>
            )}
            {/*office details table */}
            {offices?.length != 0 && (
              <Container id="nearby-office" fixed className="w-full">
                <h2
                  className="t-22-28 font-bold mb-8 font-frutiger color-ba-primary-black "
                  aria-label="nearby-label"
                >
                  {`Other ${jvService?.fullName} Offices`}
                </h2>
                <div className="grid  grid-cols-12 gap-3 ">
                  <React.Fragment>
                    <OfficesList
                      localOfficePage={localOfficePage}
                      offices={offices}
                      serviceList={serviceList}
                      isFindAnOfficePage={false}
                      isJv={office?.isJV}
                      jvService={jvService}
                    />
                  </React.Fragment>
                </div>
              </Container>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
}

export default JvPage;
