/* eslint-disable react-hooks/exhaustive-deps */
'use client';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Divider, SelectChangeEvent } from '@mui/material';
import {
  CmsImage,
  DisableAnimationsMobile,
  SelectBox,
  WrapperContainer,
  getWrapperProps
} from '@bayada/shared/ui-components';
import { useEffect, useState } from 'react';
import React from 'react';
import { useRouter } from 'next/navigation';
import { PlacesAutocomplete } from '../places-auto-complete/places-auto-complete';
import { GoogleMapContextProvider } from '../../app/context/google-map-context-provider';
import { LazyMotion, domAnimation, m } from 'framer-motion';
import {
  goToFindOffice,
  isAutoCompleteFieldValid
} from 'apps/bayada/app/find-an-office/office-service';
import { serviceDataProps } from 'apps/bayada/app/find-an-office/page';
import { useAppContext } from 'apps/bayada/app/context/app-context';

export interface ServiceProps {
  fullName: string;
  masterDataId: string;
  shortName: string;
}

export interface FindCareProps {
  buttonAriaLabel: string;
  buttonIcon: any;
  buttonText: string;
  internalName: string;
  searchInputAriaLabel: string;
  searchLabel: string;
  services: serviceDataProps[];
  defaultService: serviceDataProps;
  typeOfCareAriaLabel: string;
  typeOfCareLabel: string;
  id?: string;
  alignment?: string;
  isBanner?: boolean | null;
}

/**
 * This component renders the Find Care form. The component is a controlled
 * component and expects props to be passed from its parent component. The
 * component also makes use of react hooks for managing its state.
 *
 * The component takes in props from its parent and maps the services array to
 * an array of objects that can be used by the SelectBox component. The
 * component also sets a flag to disable the Find Care button until both the
 * location and service are selected.
 *
 * The component has a couple of event handlers. The handleSelect handler
 * updates the state of the selectedService state variable when the selection
 * changes in the SelectBox. The handleClick handler updates the router with
 * the selected location and service when the Find Care button is clicked.
 *
 * The component uses the GoogleMapContextProvider and PlacesAutocomplete
 * components from the React Google Maps library to render a search bar for
 * location.
 *
 */
export function FindCare(props: FindCareProps) {
  const findCareCmsData = props;
  const router = useRouter();
  const [selectedAddress, setSelectedAddress] = useState<string>();
  const [selectedService, setSelectedService] = useState<string>();
  const [serviceList, setServiceList] = useState<any>();
  const [isAutocompleteValid, setIsAutocompleteValid] =
    useState<boolean>(false);
  const [enteredLoc, setEnteredLoc] = useState<string | undefined | null>(null);
  const { context } = useAppContext();
  const { userAgent } = context || {};

  /* This return value of the `getWrapperProps` as true or false. Based on the value, the component will render inside the `WrapperContainer` */
  const { wrapperContainer } = getWrapperProps(props);

  /**
   * Handler for when the location is selected from the PlacesAutocomplete
   * component. It sets the selectedAddress state variable to the formatted
   * address.
   */
  const onSelectLocation = (
    place: google.maps.places.PlaceResult | null | undefined
  ) => {
    if (place?.formatted_address) {
      setIsAutocompleteValid(true);
      setSelectedAddress(place?.formatted_address);
      setEnteredLoc(null);
    }
  };

  /**
   * Handler for when the Find Care button is clicked. It updates the router
   * with the selected location and service.
   */
  const handleClick = () => {
    const response = goToFindOffice(
      enteredLoc,
      selectedAddress,
      selectedService
    );
    response?.then(
      (url) => {
        if (url && typeof url === 'string') {
          if (userAgent === 'Firefox') {
            window.location.href = url;
          } else {
            router.push(url);
          }
        }
      },
      (err) => {
        console.log('Error fetching the location....');
      }
    );
  };

  const handleGetLocation = (loc: any) => {
    setEnteredLoc(loc?.formatted_address);
    setIsAutocompleteValid(true);
    setSelectedAddress(loc?.formatted_address);
  };

  /**
   * Handler for when the selection changes in the SelectBox component. It sets
   * the selectedService state variable to the selected value.
   */
  const handleSelect = (event: SelectChangeEvent) => {
    setSelectedService(event?.target?.value);
  };

  /**
   * Effect hook that is called when the props change. It maps the services
   * array to an array of objects that can be used by the SelectBox
   * component. The mapped objects have the service short name, full name and
   * the service master data ID as properties.
   */
  useEffect(() => {
    setServiceList(
      props?.services?.map((item: any) => {
        return {
          value: item?.fields?.fullName,
          text: item?.fields?.fullName,
          sysId: item?.fields?.masterDataId
        };
      })
    );

    // set default service
    setSelectedService(findCareCmsData?.defaultService?.fullName || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleAutocompleteChnge = (evnt: any) => {
    setSelectedAddress(undefined);
    setEnteredLoc(evnt?.target?.value);
    const isValid = isAutoCompleteFieldValid(evnt?.target?.value);
    setIsAutocompleteValid(isValid);
  };
  return (
    <div
      className={`flex flex-col min-w-0 grow ${findCareCmsData?.alignment === 'left' && 'items-start'}`}
    >
      <WrapperContainer wrapper={wrapperContainer} className="py-12">
        <LazyMotion features={domAnimation}>
          <DisableAnimationsMobile>
            <m.div
              initial={{
                opacity: 0,
                scale: 0.95
              }}
              whileInView={{
                opacity: 1,
                scale: 1
              }}
              transition={{
                duration: 0.5
              }}
              viewport={{ once: true, amount: 0.5 }}
              className={`input-group-with-cta flex sm:flex-row gap-2 sm:gap-0 w-full flex-col rounded-md bg-white py-5 px-6 sm:p-2 ${findCareCmsData?.alignment === 'left' ? 'mr-auto' : 'mx-auto'} will-change-[opacity,transform] ${findCareCmsData?.isBanner && 'md:w-3/4 lg:w-3/5 xl:w-1/2'}`}
            >
              <SelectBox
                className="white w-full lg:w-1/3 flex-1 min-w-0"
                fieldLabel={findCareCmsData?.typeOfCareLabel}
                variant="standard"
                labelClass="white-select-label"
                data={serviceList}
                onSelect={handleSelect}
                formClass="find-care"
                defaultValue={selectedService}
              />

              <Divider
                className="lg:h-full"
                sx={{
                  minHeight: {
                    sm: 58
                  },
                  marginRight: {
                    sm: 1
                  },
                  marginLeft: {
                    sm: 1
                  },
                  height: {
                    base: '1px',
                    sm: '100%'
                  },
                  borderBottomWidth: {
                    base: '1px',
                    sm: '0'
                  }
                }}
                orientation="vertical"
              />

              <div className="w-full lg:w-1/3  flex-1">
                <GoogleMapContextProvider>
                  <PlacesAutocomplete
                    label={findCareCmsData.searchLabel}
                    onSelect={onSelectLocation}
                    hasInitialLocation={true}
                    icon={false}
                    handleAutocompleteChange={handleAutocompleteChnge}
                    id={findCareCmsData?.id ? findCareCmsData?.id : 'findCare'}
                    getLocation={(place) => handleGetLocation(place)}
                  />
                </GoogleMapContextProvider>
              </div>

              <Divider
                sx={{
                  minHeight: 58,
                  marginRight: 1,
                  marginLeft: 1,
                  display: {
                    base: 'none',
                    sm: 'block'
                  }
                }}
                orientation="vertical"
                className="lg:h-full"
              />

              <Button
                variant="contained"
                color="primary"
                disableFocusRipple
                className="icon-btn-movement  w-full  md:ml-1 md:mt-0 sm:w-1/4 xxl:w-1/5 !mt-3 sm:!mt-0"
                endIcon={
                  findCareCmsData?.buttonIcon && (
                    <CmsImage {...findCareCmsData?.buttonIcon}></CmsImage>
                  )
                }
                onClick={handleClick}
                disabled={!selectedService || !isAutocompleteValid}
              >
                <span className="t-15 font-frutiger font-medium leading-snug">
                  {findCareCmsData?.buttonText}
                </span>
              </Button>
            </m.div>
          </DisableAnimationsMobile>
        </LazyMotion>
      </WrapperContainer>
    </div>
  );
}

export default FindCare;
