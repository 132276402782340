/* eslint-disable @typescript-eslint/no-explicit-any */

'use client';
import { AnimatePresence, m, LazyMotion, domAnimation } from 'framer-motion';
import { CmsImageProps, SortOptions, Sys } from '@bayada/interfaces';
// import styles from './resources-list.module.scss';
import { Button, Container, Divider } from '@mui/material';
// import Image from 'next/image';
import { useCallback, useEffect, useState, useTransition } from 'react';
import {
  ServiceProps,
  ServicesList
} from '../resources-components/services-list';
import { contentfulClient } from 'apps/bayada/config/contentful-client';
import { DropDownOption } from 'libs/shared/ui-components/src/lib/dropdown-button/dropdown-button';
import { ArticlesProps } from '../types/articles/articles';
import { CaseStudiesProps } from '../types/case-study/case-study';
import {
  parseArticleTags,
  parsepageData
} from 'apps/bayada/utils/page-data-parser';
import { useDebounce } from '@uidotdev/usehooks';
import ArticleTags, { ArticleTag } from '../resources-components/tags';
import ResourcesSearchBar from './resources-search-bar';
import ResourcesSortFilter from './resources-sort-filter';
import ResourcesListItem from './resources-list-item';
import ResourcesButtonVariant from './resources-button-variants';
import Banner from '../../banner/banner';
import { WhitePapersProps } from '../types/white-papers/white-papers';
import { useAppContext } from 'apps/bayada/app/context/app-context';
import { DisableAnimationsMobile } from '@bayada/shared/ui-components';

// import { EntriesQueries, EntrySkeletonType } from 'contentful';

const contentful = contentfulClient();

export type ResourcesTypes =
  | 'news'
  | 'whitepapers'
  | 'articles'
  | 'partner success stories'
  | 'case studies';

export type toggleButtonVariant = 'back' | 'filter';

export interface ResourcesListProps {
  bannerHeading: string;
  type: ResourcesTypes;
  sortByLabel?: string;
  sortByOptions: DropDownOption[];
  categoryLabel?: string;
  tagLabel?: string;
  searchInputLabel?: string;
  searchInputIcon?: CmsImageProps | null;
  showMoreLabel?: string;
  loadMoreLabel?: string;
  clearAllLabel?: string;
  noOfCardsReturned?: number;
  sys?: Sys;
}

export interface ResourceItem {
  slug?: string;
  resource?: WhitePapersProps | CaseStudiesProps | ArticlesProps;
  type?: ResourcesTypes;
}

/**
 * Returns the corresponding resource type based on the provided type.
 *
 * @param {ResourcesTypes} type - The type of resource.
 * @returns {string} - The corresponding resource type.
 */
function getResourceType(type: ResourcesTypes): string {
  switch (type) {
    case 'articles':
      return 'articles';
    case 'whitepapers':
      return 'whitepapers';
    case 'case studies':
      return 'caseStudy';
    case 'news':
      return 'news';
    case 'partner success stories':
      return 'partnerSuccessStory';
    default:
      return '';
  }
}

/**
 * @description - Renders a list of resources with filtering and sorting options.
 *
 * This component provides a user interface for displaying a list of resources, such as articles, whitepapers, news, partner success story, case study
 * with options for filtering and sorting. It allows users to search for specific resources, apply filters
 * based on categories and tags, and sort the displayed resources by different criteria.
 *
 * @param {ResourcesListProps} props - The props passed to the component
 * @returns - The JSX element to be rendered.
 */
export function ResourcesList(props: ResourcesListProps) {
  const {
    bannerHeading,
    type,
    categoryLabel = 'Category',
    clearAllLabel = 'Clear all',
    loadMoreLabel = ' Load more',
    noOfCardsReturned = 1,
    searchInputIcon,
    searchInputLabel = 'Search',
    showMoreLabel = 'Show more',
    sortByLabel = 'Sort by',
    sortByOptions,
    tagLabel = 'Tags'
  } = props || {};
  const { context } = useAppContext();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSortOption, setSortOption] = useState<DropDownOption>();
  const [selectedService, setSelectedService] = useState<ServiceProps[]>();
  const [resourcesList, setResourcesList] = useState<ResourceItem[]>([]);
  const [articleTags, setArticleTags] = useState<ArticleTag[]>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isPending, startTransition] = useTransition();
  const debouncedSearchTerm = useDebounce(searchQuery, 300);
  const [selectedTag, setSelectedTag] = useState<ArticleTag | null>(null);
  const [resourcesListLimit, setResourcesListLimit] = useState<number>(0);
  const [noOfResourcesToSkip, setNoOfResourcesToSkip] = useState<number>(0);

  /**
   * @description Fetches a list of resources from Contentful based on search term, skip, sort order, service, and tag.
   *
   * @param {string} searchTerm - Search term.
   * @param {number} noOfResourcesToSkip - Number of resources to skip.
   */
  const getResourcesList = useCallback(
    async (searchTerm?: string, noOfResourcesToSkip?: number) => {
      try {
        const resourceType = getResourceType(type);
        const sortOrder = selectedSortOption?.label as SortOptions;
        const queryOptions: any = {
          skip: noOfResourcesToSkip || 0,
          limit: noOfCardsReturned || 9,
          include: 10,
          content_type: resourceType,
          select: ['sys']
        };

        if (sortOrder === 'oldest') {
          queryOptions.order = 'sys.createdAt';
        } else if (sortOrder === 'z to a') {
          // queryOptions.order = '-fields.title';
        } else if (sortOrder === 'a to z') {
          // queryOptions.order = 'fields.title';
        } else {
          queryOptions.order = '-sys.createdAt';
        }

        // Apply filter based on selected services
        if (selectedService && selectedService?.length > 0) {
          const serviceIds = selectedService?.map((service) => {
            return service?.sys?.id;
          });
          queryOptions['fields.services.sys.id[in]'] = serviceIds;
        } else {
          delete queryOptions['fields.services.sys.id[in]'];
        }

        // Apply search term filter
        if (searchTerm) {
          queryOptions.query = `${searchTerm}`;
        } else {
          delete queryOptions?.query;
        }

        // Apply filter based on selected tag
        if (selectedTag) {
          queryOptions['fields.tags.sys.id[in]'] = selectedTag?.sys?.id;
        } else {
          delete queryOptions['fields.tags.sys.id[in]'];
        }
        const response = await contentful?.getEntries(queryOptions);
        // Set the total number of resources available
        setResourcesListLimit(response?.total);
        const searchedResourcesIds = response?.items?.map(
          (item) => item?.sys?.id
        );
        if (searchedResourcesIds?.length > 0) {
          startTransition(async () => {
            const pageEntries = await context?.ctfClient?.getEntries({
              content_type: 'page',
              'fields.templateReference.sys.id[in]': searchedResourcesIds,
              include: 10,
              order: queryOptions.order
            });
            const parsedData = pageEntries?.items?.map((item) => {
              const resourceTemplate = item?.fields?.templateReference as any;
              const templateId = resourceTemplate?.sys?.contentType?.sys.id;
              const pageData = parsepageData(templateId, resourceTemplate);
              const obj: ResourceItem = {
                slug: item?.fields?.slug?.toLocaleString(),
                resource: { ...pageData, sys: resourceTemplate?.sys },
                type: type
              };
              return obj;
            });
            setResourcesList((prevData) => [...prevData, ...parsedData]);
          });
        } else {
          setResourcesList([]);
        }
      } catch (error) {
        console.log(error, '::: getResourcesListList error');
      }
    },
    [
      type,
      selectedSortOption?.label,
      noOfCardsReturned,
      selectedService,
      selectedTag,
      context?.ctfClient
    ]
  );

  /**
   * @description Fetches a list of article tag available from contentful.
   * This function retrieves the total article entries from contentful,
   * extracts their tags, removes duplicates, and updates the state with the unique tags.
   *
   * @returns {void}
   */
  const getTagsList = async () => {
    try {
      const response = await contentful?.getEntries({
        content_type: 'articles',
        select: ['fields.tags'],
        limit: 500
      });
      const tagsList = response?.items;
      const list: ArticleTag[] = [];
      tagsList?.forEach((item) => {
        return (item?.fields?.tags as any)?.map((tag: any) => {
          const aa = parseArticleTags(tag);
          const ifExist = list?.filter(
            (item) => item?.articleTag === aa?.articleTag
          );
          if (aa && ifExist?.length === 0) {
            list.push(aa);
          }
        });
      });

      const unique = list?.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      setArticleTags(unique);
    } catch (error) {
      console.log(error, '::: getTagsList error');
    }
  };

  /**
   * Toggle the side bar visibility and updates the body's overflow style accordingly.
   */
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    document.body.style.overflow = isOpen ? 'auto' : 'hidden';
  };

  /**
   * Handles the selection of an article tag and updates the selected tag state.
   *
   * @param {(ArticleTag | null)} tag - The selected article tag object or null if no tag is selected.
   */
  const handleTagSelect = (tag: ArticleTag | null) => {
    setSelectedTag(tag);
  };

  /**
   * Handles changes to the search query input field and updates the search query state.
   *
   * @param {string} query - The new search query string.
   */
  const searchTextOnChnage = (query: string) => {
    setSearchQuery(query);
  };

  /**
   * Handles clicks on the "Show More" button by updating the number of items to skip and fetching,
   *  more resources from contentful.
   */
  const handleShowMoreButtonClick = () => {
    // Set the number of items to skip
    const noOfItemsToSkip = noOfResourcesToSkip + noOfCardsReturned;
    setNoOfResourcesToSkip(noOfItemsToSkip);
    if (noOfItemsToSkip > 0) {
      getResourcesList('', noOfItemsToSkip);
    }
  };

  useEffect(() => {
    getTagsList();
  }, []);

  // Delayed searching
  useEffect(() => {
    // Resetting the resource list array whenever any filter is applied.
    setResourcesList([]);
    setNoOfResourcesToSkip(0);
    getResourcesList(debouncedSearchTerm);
  }, [debouncedSearchTerm, getResourcesList, selectedSortOption]);

  // Callback fn to render the article tag section
  const renderArticleTagSection = useCallback(() => {
    if (!articleTags) return null;

    return (
      <div className=" flex-col flex ">
        <ArticleTags
          tags={articleTags}
          title={tagLabel}
          isRadioGroup
          onTagSelect={handleTagSelect}
          showMoreLabel={showMoreLabel}
        />
      </div>
    );
  }, [articleTags, tagLabel, showMoreLabel]);

  // Callback fn to render the services list section
  const renderServicesList = useCallback(() => {
    return (
      <ServicesList
        categoryLabel={categoryLabel}
        clearAllLabel={clearAllLabel}
        showMoreLabel={showMoreLabel}
        onSelect={(option) => setSelectedService(option)}
      />
    );
  }, [categoryLabel, clearAllLabel, showMoreLabel]);

  // Callback fn to render the toggle buttons
  const renderToggleButtonVariant = useCallback(
    (btnType: toggleButtonVariant, onclick: () => void) => {
      return <ResourcesButtonVariant buttonType={btnType} onClick={onclick} />;
    },
    []
  );

  // Callback fn to render resources list sorting filters.
  const renderResourcesSortFilter = useCallback(
    ({ isDropdown = false }) => {
      return (
        <ResourcesSortFilter
          isDropdown={isDropdown}
          sortByLabel={sortByLabel}
          sortByOptions={sortByOptions}
          onButtonClick={(option) => setSortOption(option)}
        />
      );
    },
    [sortByLabel, sortByOptions, setSortOption]
  );

  const transitionConfig = {
    duration: 0.9,
    ease: 'backInOut'
  };
  const slideFromLeft = {
    whileInView: {
      opacity: 1,
      transform: 'translate(0px , 0px)'
    },
    initial: {
      opacity: '0',
      transform: 'translate(-20px , 0)'
    }
  };

  return (
    <div className="flex flex-col">
      {bannerHeading && (
        <AnimatePresence mode="wait">
          <LazyMotion features={domAnimation} key={'banner-animation-lazy'}>
            <DisableAnimationsMobile key={'banner-animation-disable'}>
              <m.div
                key={'banner-animation'}
                viewport={{ once: true }}
                transition={transitionConfig}
                whileInView={{
                  opacity: 1
                }}
                initial={{
                  opacity: '0'
                }}
                className="mb-10 will-change-[opacity]"
              >
                <Banner
                  internalName={'resource-listing-page-banner'}
                  heading={bannerHeading}
                  textColor="black"
                  contentAlignment="left"
                />
              </m.div>
            </DisableAnimationsMobile>
          </LazyMotion>
        </AnimatePresence>
      )}
      <Container fixed className="mx-auto w-full">
        <div className="grid grid-flow-row grid-cols-1 sm:grid-cols-3 sm:gap-7 md:gap-4 lg:grid-cols-4 pb-10">
          <div
            className="col-span-1 md:w-[80%] min-h-0 flex flex-col min-w-0"
            aria-label="listing-container"
          >
            <AnimatePresence mode="wait">
              <LazyMotion
                features={domAnimation}
                key={'content-animation-lazy'}
              >
                <DisableAnimationsMobile key={'content-animation-disable'}>
                  <m.h2
                    key={'content-animation'}
                    viewport={{ once: true }}
                    transition={transitionConfig}
                    whileInView={slideFromLeft?.whileInView}
                    initial={slideFromLeft.initial}
                    className="t-18-21 mb-8 font-frutiger font-bold will-change-transform"
                  >
                    Total {resourcesListLimit} {type} found
                  </m.h2>
                </DisableAnimationsMobile>
              </LazyMotion>
              <div className="mb-5 mt-5">
                {/* Search bar*/}
                <LazyMotion
                  features={domAnimation}
                  key={'mobile-view-animation-lazy'}
                >
                  <DisableAnimationsMobile
                    key={'mobile-view-animation-disable'}
                  >
                    <m.div
                      key={'mobile-view-animation'}
                      viewport={{ once: true }}
                      transition={transitionConfig}
                      whileInView={slideFromLeft?.whileInView}
                      initial={slideFromLeft.initial}
                      className="w-full flex gap-6 min-h-0 will-change-transform"
                    >
                      <ResourcesSearchBar
                        searchInputIcon={searchInputIcon}
                        onChange={searchTextOnChnage}
                        searchInputLabel={searchInputLabel}
                      />
                      <div className="sm:hidden h-full">
                        {renderToggleButtonVariant('filter', toggleSidebar)}
                      </div>
                    </m.div>
                  </DisableAnimationsMobile>
                </LazyMotion>

                {/* Mobile */}
                {isOpen && (
                  <div
                    className={`sm:hidden p-5 fixed top-0 right-[100%] bg-white z-[1400] flex flex-col gap-5 transition-right duration-500 ease-in overflow-y-scroll origin-left
                    ${isOpen ? '!right-[0%] w-screen h-dvh scale-100' : 'scale-0'}}`}
                    data-lenis-prevent
                    aria-label="filter-section-mobile"
                  >
                    <div className="flex">
                      <span>
                        {renderToggleButtonVariant('back', toggleSidebar)}
                      </span>
                      <h2 className="t-18-21 font-frutiger font-bold">
                        Filter
                      </h2>
                    </div>
                    <Divider
                      orientation="horizontal"
                      className="mt-4"
                      flexItem
                    />
                    {renderResourcesSortFilter({ isDropdown: false })}
                    <Divider
                      orientation="horizontal"
                      className="mt-4"
                      flexItem
                    />
                    {renderServicesList()}
                    <Divider
                      orientation="horizontal"
                      className="mt-4"
                      flexItem
                    />
                    {articleTags && renderArticleTagSection()}
                  </div>
                )}
              </div>

              <LazyMotion
                features={domAnimation}
                key={'service-tag-animation-lazy'}
              >
                <DisableAnimationsMobile key={'service-tag-animation-disable'}>
                  <m.div
                    key={'service-tags-animation'}
                    viewport={{ once: true }}
                    transition={transitionConfig}
                    whileInView={slideFromLeft?.whileInView}
                    initial={slideFromLeft.initial}
                    className={`bottom hidden  sm:flex flex-col gap-5 transition-all duration-500 ease-in-out will-change-transform}`}
                  >
                    <Divider
                      orientation="horizontal"
                      className="mt-4"
                      flexItem
                    />
                    {renderServicesList()}

                    <Divider
                      orientation="horizontal"
                      className="mt-4"
                      flexItem
                    />
                    {articleTags && renderArticleTagSection()}
                  </m.div>
                </DisableAnimationsMobile>
              </LazyMotion>
            </AnimatePresence>
          </div>

          {/* right section */}
          <div className={`flex flex-col gap-5  sm:col-span-2 lg:col-span-3`}>
            <div
              className="sm:flex justify-end items-center gap-3 hidden "
              aria-label="sort-desktop"
            >
              {renderResourcesSortFilter({ isDropdown: true })}
            </div>
            {isPending && <div className="page-loader"></div>}
            {resourcesList && resourcesList?.length > 0 ? (
              <>
                <ResourcesListItem resourcesList={resourcesList} />
                {resourcesList?.length !== resourcesListLimit && (
                  <div className="text-center">
                    <Button
                      variant="outlined"
                      color="secondary"
                      disableFocusRipple
                      aria-label={`${loadMoreLabel}-button`}
                      onClick={handleShowMoreButtonClick}
                      className="sm:w-full, md:w-auto"
                    >
                      <span className="t-15 font-frutiger font-medium leading-snug">
                        {loadMoreLabel}
                      </span>
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <div className="w-full">
                <h4 className="t-18-21 mb-8 font-frutiger font-bold text-center">
                  No Resources Found with current filters.
                </h4>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ResourcesList;
