/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Alert,
  AlertProps,
  CmsImage,
  CtaButtonProps,
  Icon
} from '@bayada/shared/ui-components';
import { Container } from '@mui/material';
import NavigationLink, {
  NavigationLinkProps
} from '../navigation-link/navigation-link';
import { useState } from 'react';
import { SiteSearchInputField } from '../navigation-header/site-search-input/site-search-input';
import { usePathname } from 'next/navigation';

export type TopNavigationProps = {
  alert?: AlertProps | null;
  menuItems?: NavigationLinkProps[];
  search?: CtaButtonProps | null;
  sysId?: string; //for checking api
  searchButtonVisible?: boolean;
  floatingLinks?: NavigationLinkProps[];
};

/**
 * This TypeScript React code defines a TopNavigation component with props for alert, menu items,
 * search functionality, and system ID checking.
 * @property {AlertProps | null} alert - The `alert` property in the `TopNavigationProps` type is used
 * to pass props to the `Alert` component. The `Alert` component displays a message to the user,
 * typically used for showing notifications or important information. The `AlertProps` type defines the
 * props that can be passed to
 * @property {NavigationLinkProps[]} menuItems - The `menuItems` property in the `TopNavigationProps`
 * type is an array of objects with the shape of `NavigationLinkProps`. Each object represents a menu
 * item that can be displayed in the top navigation bar. The `menuItems` array can contain multiple
 * menu items, and each menu item
 * @property search - The `search` property in the `TopNavigationProps` type is an object that contains
 * the following fields:
 * @property {string} sysId - The `sysId` property in the `TopNavigationProps` type is used for
 * checking the API. It seems like it is intended to be a unique identifier or system ID that can be
 * used for API requests or validations within the component or application. This property can help in
 * identifying or referencing specific data
 * @property {boolean} searchButtonVisible - The `searchButtonVisible` property in the
 * `TopNavigationProps` type is a boolean that determines whether the search button should be visible
 * in the top navigation bar. If `searchButtonVisible` is set to `true`, the search button will be
 * displayed, allowing users to click on it to open
 */

export const TopNavigation: React.FC<TopNavigationProps> = (
  props: TopNavigationProps
) => {
  const { alert, menuItems, searchButtonVisible, search } = props || {};
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const isSearchButtonVisible = searchButtonVisible;
  const pathname = usePathname();

  const handleSearchClick = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  return (
    <div className="top-bar hidden items-center bg-ba-gray-75 lg:flex flex-col relative">
      <Container
        fixed
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div className="flex justify-between py-2 items-end gap-4">
          <div className={`top-bar-left ${alert && 'max-w-[50%] shrink-0'}`}>
            <ul className="navigation-list flex items-center gap-6 custom-scrollbar">
              {/* hide search button based on isSearchButtonVisible flag and if the current route path is /search */}
              {!isSearchButtonVisible && !pathname?.includes('/search') && (
                <li className="flex items-center">
                  <button
                    aria-label={search?.ariaLabel ?? 'search-label'}
                    onClick={handleSearchClick}
                    className="gap-1 t-13 font-frutiger leading-none no-underline inline-flex items-center"
                  >
                    {search?.iconPosition === 'before text' &&
                      (search?.buttonText ?? 'Search')}
                    {isSearchOpen ? (
                      <Icon
                        iconName="close"
                        className="svg-icon icon-20 flex items-center justify-center"
                      />
                    ) : search?.icon ? (
                      <CmsImage
                        {...search?.icon}
                        width={20}
                        height={20}
                        className="svg-icon icon-20 flex items-center justify-center"
                      />
                    ) : (
                      <Icon
                        iconName="search"
                        className="svg-icon icon-20 flex items-center justify-center"
                      />
                    )}
                    {search?.iconPosition !== 'before text' &&
                      (search?.buttonText ?? 'Search')}
                  </button>
                </li>
              )}
              {menuItems?.map((item) => {
                return (
                  item?.text && (
                    <li
                      key={item?.text}
                      className="nav-item flex items-center no-underline !m-0"
                    >
                      {item?.text !== 'search icon' && (
                        <NavigationLink
                          {...item}
                          className="t-13  font-frutiger leading-none no-underline"
                          textClass="font-frutiger"
                        />
                      )}
                    </li>
                  )
                );
              })}
            </ul>
          </div>
          {alert && (
            <div
              className="top-bar-right text-left lg:pr-1 lg:text-right custom-scrollbar overflow-x-auto"
              aria-label="alert-with-link"
            >
              <Alert
                {...alert}
                varient={'default'}
                containerClassName="lg:ml-auto"
                alertBodyClassName="inline-block items-center lg:inline"
                textClass="t-13"
              />
            </div>
          )}
        </div>
      </Container>
      {isSearchOpen && (
        <SiteSearchInputField
          width="80%"
          className="search-div lg:p-16 z-20 absolute top-9 left-0 w-full bg-white flex justify-center"
          handleVisibilityOnSubmit={(flag) => {
            setIsSearchOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default TopNavigation;
