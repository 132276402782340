/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import React, { useCallback, useEffect, useState } from 'react';
import {
  DisableAnimationsMobile,
  Heading,
  UserDetailsCard,
  WrapperContainer,
  getWrapperProps
} from '@bayada/shared/ui-components';
import { Alignment, HeadingValue, ICmsImage } from '@bayada/interfaces';

import { m, LazyMotion, domAnimation } from 'framer-motion';
import { useAppContext } from 'apps/bayada/app/context/app-context';
import { MarkDown } from '@bayada/shared/ui-components';

export type PeoplePageProps = {
  internalName: string;
  heading: string;
  headingValue?: HeadingValue;
  subHeading?: string;
  alignment?: Omit<Alignment, 'bottom'>;
  backGroundColor: string;
  peopleItem: PeopleItemProps[];
  sysId: string;
};

export type PeopleItemProps = {
  sysId: string | number;
  designation: string;
  designationTitle?: string;
  firstName: string;
  lastName?: string;
  image: ICmsImage | null;
  slug: string | null;
};
/**
 * This code defines a React component called People, which is used to render a list of people.
 * It conditionally sets CSS classes based on the alignment and backGroundColor props.
 * It loops through the peopleItem array and renders UserDetailsCard component for each person, passing relevant props like slug, sysId, image, name, designation, imageBorderRadius, and alignment.
 * @param {PeoplePageProps} props
 */
export function People(props: PeoplePageProps) {
  const {
    heading,
    headingValue = 'h2',
    subHeading,
    alignment,
    backGroundColor,
    peopleItem
  } = props || {};

  const { context } = useAppContext();
  const [updatedPeopleItem, setUpdatedPeopleItem] = useState<PeopleItemProps[]>(
    []
  );
  /**
   * getPageData
   * Method is used to find out the page related to the corresponding people item slug.
   * It also updates the current item slug with the parent page slug as prefix.
   */
  const getPageData = useCallback(async () => {
    const slugArray: string[] = peopleItem
      ?.filter((item) => item?.slug)
      .map((x) => String(x?.slug).replace('/', '').trim());
    const contentful = context?.ctfClient;
    const query: any = {
      content_type: 'page',
      'fields.slug[in]': slugArray.join(','),
      select: ['fields.parentPage', 'fields.slug'],
      include: 10
    };
    const result = await contentful?.getEntries(query);
    peopleItem?.forEach((item) => {
      const matchingData = result?.items?.find(
        (x: any) =>
          x?.fields.slug === String(item?.slug).replace('/', '').trim() &&
          x?.fields?.parentPage
      );
      if (matchingData) {
        item.slug =
          String((matchingData?.fields?.parentPage as any)?.fields?.slug) +
          '/' +
          String(item?.slug).replace('/', '').trim();
      }
    });
    setUpdatedPeopleItem(peopleItem);
  }, [context?.ctfClient, peopleItem]);

  useEffect(() => {
    getPageData();
  }, [getPageData]);

  /* This return value of the `getWrapperProps` as true or false. Based on the value, the component will render inside the `WrapperContainer` */
  const { wrapperContainer } = getWrapperProps(props);
  let textPositionCss = 'text-center';
  let bgColor = 'bg-white';

  if (alignment === 'left') {
    textPositionCss = 'text-left';
  } else if (alignment === 'right') {
    textPositionCss = 'text-right';
  }

  if (backGroundColor === 'grey') {
    bgColor = 'bg-ba-gray-75';
  }

  return (
    <div aria-label="people-container" className={`py-12 md:py-14 ${bgColor}`}>
      <WrapperContainer wrapper={wrapperContainer} className="relative mx-auto">
        <div
          className={`w-full mx-auto md:grid-cols-12 md:grid ${textPositionCss}`}
        >
          <LazyMotion features={domAnimation}>
            <DisableAnimationsMobile>
              <div className="lg:col-span-8 lg:col-start-3 md:col-span-10 md:col-start-2">
                {heading && (
                  <m.div
                    className="will-change-[opacity,transform]"
                    viewport={{ once: true }}
                    initial={{
                      opacity: '0',
                      transform: 'translate(-20px , 0)'
                    }}
                    transition={{
                      duration: 0.9,
                      ease: 'backInOut'
                    }}
                    whileInView={{
                      opacity: 1,
                      transform: 'translate(0px , 0px)'
                    }}
                  >
                    <Heading
                      type={headingValue}
                      className="text-primary-black mb-6"
                      aria-label="people-heading"
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: MarkDown(heading) }}
                      />
                    </Heading>
                  </m.div>
                )}
                {subHeading && (
                  <m.p
                    viewport={{ once: true }}
                    initial={{
                      opacity: '0',
                      transform: 'translate(-20px , 0)'
                    }}
                    transition={{
                      duration: 0.9,
                      ease: 'backInOut'
                    }}
                    whileInView={{
                      opacity: 1,
                      transform: 'translate(0px , 0px)'
                    }}
                    className="t-16-19 mx-auto font-normal color-ba-gray-900 will-change-[opacity,transform]"
                    aria-label="people-subheading"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: MarkDown(subHeading) }}
                    />
                  </m.p>
                )}
              </div>
            </DisableAnimationsMobile>
          </LazyMotion>
        </div>
        <div className="mx-auto min-h-80 max-w-[80.063rem] mt-10 sm:mt-14">
          <LazyMotion features={domAnimation}>
            <div className="align-center lg:justify-center -mx-4 flex flex-wrap  px-4 gap-y-6 sm:gap-y-8 ">
              {updatedPeopleItem?.length &&
                updatedPeopleItem?.map(
                  (item: PeopleItemProps, index: number) => (
                    <DisableAnimationsMobile key={index}>
                      <m.div
                        key={index}
                        viewport={{ once: true }}
                        initial={{
                          opacity: '0',
                          transform: 'translate(-20px , 0)'
                        }}
                        transition={{
                          duration: 0.9 + index * 0.1,
                          ease: 'backInOut'
                        }}
                        whileInView={{
                          opacity: 1,
                          transform: 'translate(0px , 0px)'
                        }}
                        className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 will-change-[opacity,transform]"
                      >
                        <UserDetailsCard
                          key={index}
                          slug={item?.slug}
                          id={item?.sysId}
                          image={item?.image}
                          name={
                            item?.firstName +
                            (item?.lastName ? ' ' + item.lastName : '')
                          }
                          designation={item?.designation}
                          imageBorderRadius="full"
                          alignment="center"
                        />
                      </m.div>
                    </DisableAnimationsMobile>
                  )
                )}
            </div>
          </LazyMotion>
        </div>
      </WrapperContainer>
    </div>
  );
}

export default People;
