/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import { Container, Link } from '@mui/material';
import React, { Suspense, useCallback, useState } from 'react';
import {
  useContentfulInspectorMode,
  useContentfulLiveUpdates
} from '@contentful/live-preview/react';
import {
  CmsImage,
  SocialMedia,
  SocialMediaProps
} from '@bayada/shared/ui-components';
import Masonry from '@mui/lab/Masonry';
import { useInView } from 'react-intersection-observer';
import { ICmsImage } from '@bayada/interfaces';
import MenuItemGroup, {
  MenuItemGroupProps
} from '../menu-item-group/menu-item-group';
import { CmsRichText } from '../cms-rich-text/cms-rich-text';
import { Entry, EntrySkeletonType } from 'contentful';
import { parseFooterData } from 'apps/bayada/utils/component-data-parser';
import { HubSpotFormProps } from '../hub-spot-form/cms-hub-spot-form';
import { CmsHubSpotForm } from '../hub-spot-form/cms-hub-spot-form';
import { MarkDown } from '@bayada/shared/ui-components';
import { homePageUrl } from 'apps/bayada/constants';
import { useAppContext } from 'apps/bayada/app/context/app-context';

export type CmsFooterProps = {
  data?: Entry<EntrySkeletonType, undefined, string> | undefined;
};

type Footer = {
  data: FooterPageProps;
};

export interface FooterPageProps {
  internalName: string;
  accreditationImage: ICmsImage | null;
  logo?: ICmsImage | null;
  logoLinkUrl: string | null;
  logoLinkAriaLabel: string | null;
  text?: string;
  form?: HubSpotFormProps;
  menuItemGroup: MenuItemGroupProps[] | null;
  socialMediaIcons: SocialMediaProps[] | null;
  copyrightText: Document | null;
  sysId: string;
}
/**
 * Footer component renders a footer which is a part of every page on the website.
 * It is responsible for rendering a logo, social media links and a menu of
 * pages that are part of the footer. The component also renders a copyright
 * notice and an accreditation logo.
 *
 * The footer component receives its data from the Contentful CMS. The component
 * uses the useContentfulLiveUpdates hook to get updates when the data in Contentful changes.
 *
 * The component also uses the useContentfulInspectorMode hook to get inspector
 * properties for the current item being rendered. The inspector properties are
 * used to allow the Contentful web app to open the item in the editor when clicked.
 */
export function Footer(props: CmsFooterProps) {
  const [state] = useState(props?.data);
  const updatedState = useContentfulLiveUpdates(state);
  const footerData: FooterPageProps | null = parseFooterData(updatedState);
  const {
    logo = null,
    logoLinkUrl,
    logoLinkAriaLabel,
    socialMediaIcons,
    text,
    menuItemGroup,
    form,
    accreditationImage,
    copyrightText
  } = footerData ?? {};
  const { context } = useAppContext();
  const { userAgent } = context || {};
  const inspectorProperties = useContentfulInspectorMode();

  const [logoRef, inView] = useInView({
    threshold: 0.01,
    triggerOnce: false,
    rootMargin: '8px 0px'
  });

  // Memoize a function that renders a logo with the given properties
  const renderLogo = useCallback(
    ({
      logoImg: logo,
      width,
      height,
      link
    }: {
      logoImg: ICmsImage | null;
      width?: number;
      height?: number;
      link: boolean;
    }) => {
      if (!logo) {
        return null;
      }

      const logoImage = logo && (
        <figure
          className={`flex min-w-0 transition-transform delay-75 duration-300 ease-in-out will-change-transform origin-left ${inView ? 'scale-100' : 'scale-90'}`}
          ref={logoRef}
        >
          <CmsImage
            {...logo}
            width={width}
            height={height}
            style={{ width: `${width}px`, height: `${height}px` }}
          />
        </figure>
      );

      return link ? (
        <Link
          role="link"
          href={logoLinkUrl ?? homePageUrl}
          aria-live="polite"
          aria-label={logoLinkAriaLabel ?? 'Footer logo link'}
          onClick={(e) => {
            if (userAgent === 'Firefox') {
              e?.preventDefault();
              window.location.href = logoLinkUrl ?? homePageUrl;
            }
          }}
        >
          {logoImage}
          <span className="sr-only">link to homepage</span>
        </Link>
      ) : (
        logoImage
      );
    },
    [inView, logoRef, logoLinkUrl, logoLinkAriaLabel]
  );

  // Memoize a function that renders social media icons with the given data
  const renderSocialMediaIcons = useCallback(() => {
    if (!socialMediaIcons) return null;

    return socialMediaIcons.map((item: SocialMediaProps, index: number) => (
      <SocialMedia key={index} {...item} />
    ));
  }, [socialMediaIcons]);

  return (
    <footer className="app-footer shadow-black shadow-md relative">
      <Suspense fallback={<div>Footer Loading ...</div>}>
        <div
          id="trigger-block-element"
          className="bg-ba-gray-75"
          {...inspectorProperties({
            entryId: updatedState?.sys.id ?? '',
            fieldId: String(updatedState?.fields?.internalName) || ''
          })}
          role="region"
          aria-label="main content"
        >
          <Container fixed className="relative mx-auto">
            <div className="flex flex-col md:flex-row pt-10 pb-5 sm:pt-20 sm:pb-10">
              <div className="grid sm:grid-cols-12 w-full">
                <div
                  className={`col-span-12 md:col-span-5 mb-8 sm:mb-0  ${!text && !form?.formId && 'hidden md:block'}`}
                >
                  <div className="w-full sm:w-5/6 md:max-w-[400px]">
                    <div className="hidden md:block lg:mb-6">
                      {logo &&
                        renderLogo({
                          logoImg: logo,
                          link: true,
                          width: 145,
                          height: 45
                        })}
                      <div
                        className="flex gap-2 align-center my-4"
                        aria-label="social-media"
                      >
                        {renderSocialMediaIcons()}
                      </div>
                    </div>

                    {text && (
                      <h2 className="text-3xl sm:text-lg pt-4 mb-6 font-frutiger set-frutiger font-bold">
                        <span
                          dangerouslySetInnerHTML={{ __html: MarkDown(text) }}
                        />
                      </h2>
                    )}
                    {form?.formId && (
                      <div className="mb-4 lg:mb-0">
                        <CmsHubSpotForm
                          id={'footerForm'}
                          formId={form?.formId}
                          customClass={form?.customClass}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-span-12 md:col-span-7">
                  <Masonry
                    className="min-h-48"
                    columns={{ base: 2, md: 3 }}
                    spacing={3}
                  >
                    {menuItemGroup ? (
                      menuItemGroup?.map(
                        (item: MenuItemGroupProps, index: number) => (
                          <MenuItemGroup
                            key={index}
                            {...item}
                            type={'menuitem'}
                            className="min-w-0"
                          />
                        )
                      )
                    ) : (
                      <div aria-hidden="true">No menu items available</div>
                    )}
                  </Masonry>
                </div>
              </div>

              <div className="md:hidden">
                {logo &&
                  renderLogo({
                    logoImg: logo,
                    link: true,
                    width: 125,
                    height: 38
                  })}
                <div className="flex gap-3 align-center mt-4">
                  {renderSocialMediaIcons()}
                </div>
              </div>
            </div>
            <div className="border-t border-solid border-ba-gray-175 py-6">
              <div className="flex gap-4">
                <div
                  className="shrink-0 w-[2.125rem]"
                  aria-label="accreditation-image"
                >
                  {accreditationImage &&
                    renderLogo({
                      logoImg: accreditationImage,
                      link: false,
                      width: 40
                    })}
                </div>
                {copyrightText && (
                  <CmsRichText
                    value={copyrightText as Document}
                    className="t-13 leading-normal"
                  />
                )}
              </div>
            </div>
          </Container>
        </div>
      </Suspense>
    </footer>
  );
}

export default Footer;
