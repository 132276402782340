/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useContext } from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import React from 'react';
import { Icon } from '@bayada/shared/ui-components';
import { GoogleMapContext } from '../../app/context/google-map-context-provider';
import { uuidv4 } from 'apps/bayada/utils/helper';

export interface PlaceAutocompleteProps {
  onSelect: (
    place: google.maps.places.PlaceResult | null | undefined,
    isButtonClick?: boolean
  ) => void;
  label?: string;
  hasInitialLocation?: boolean | null;
  icon?: boolean;
  id?: string;
  initialPlace?: google.maps.places.PlaceResult | null | undefined;
  hasSubmitButton?: boolean;
  locationInputLabel?: string;
  showLabel?: boolean;
  handleAutocompleteChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  getLocation: (loc: google.maps.places.PlaceResult) => void;
}

export const PlacesAutocomplete: React.FC<PlaceAutocompleteProps> = ({
  onSelect,
  label,
  hasInitialLocation,
  icon,
  initialPlace,
  hasSubmitButton,
  locationInputLabel = 'Enter the location',
  handleAutocompleteChange,
  getLocation,
  id,
  showLabel = false
}) => {
  const { isLoaded, loadError } = useContext(GoogleMapContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [initialLocation, setInitialLocation] = useState<string | undefined>(
    ''
  ); // Initial location value

  const queryParams = new URLSearchParams(
    typeof window !== 'undefined' ? window?.location?.search : ''
  );

  const generatedBannerUID = uuidv4();

  // Access individual query parameters
  const { address } = {
    address: queryParams.get('address')
    // service: queryParams.get('service') || ''
  };

  const [selectedLocation, setSelectedLocation] =
    useState<google.maps.places.PlaceResult | null>();

  const handleValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInitialLocation(event?.target?.value);
    handleAutocompleteChange(event);
  };

  const getAutocomplete = () => {
    if (isLoaded) {
      const locationAutoComplete = new window.google.maps.places.Autocomplete(
        document.getElementById(
          `autocomplete${id}-${generatedBannerUID}`
        ) as HTMLInputElement
      );
      setAutocomplete(locationAutoComplete);

      locationAutoComplete?.addListener('place_changed', () => {
        if (hasSubmitButton) {
          setSelectedLocation(locationAutoComplete?.getPlace());
        } else {
          onSelect(locationAutoComplete?.getPlace());
        }
        setInitialLocation(locationAutoComplete?.getPlace()?.formatted_address);
      });

      //Do not fetch current location in case of find-care
      // Fetch user's current location and set initial value
      if (
        navigator.geolocation &&
        !initialPlace &&
        !address &&
        hasInitialLocation
      ) {
        navigator?.geolocation?.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            const geocoder = new window.google.maps.Geocoder();
            const latLng = new window.google.maps.LatLng(latitude, longitude);

            geocoder.geocode({ location: latLng }, (results, status) => {
              if (status === 'OK' && results) {
                if (results[0]) {
                  setInitialLocation(results[0]?.formatted_address);
                  if (results && results[0]) {
                    getLocation(results[0]);
                  }
                }
              }
            });
          },
          (error) => {
            setInitialLocation('');
            console.error('Error getting user location:', error);
          }
        );
      } else if (initialPlace) {
        setInitialLocation(initialPlace?.formatted_address);
      }
    }
  };

  useEffect(() => {
    getAutocomplete();
  }, [isLoaded, initialPlace]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="flex items-center  w-full ">
      <FormControl
        className={`search-icon-text-field ${!icon && 'search-iconless-field'} icon-right standardInputField flex items-center rounded-md relative`}
      >
        {showLabel && (
          <label
            htmlFor={`autocomplete${id}-${generatedBannerUID}`}
            className="sr-only"
          >
            Search location
          </label>
        )}
        <TextField
          id={`autocomplete${id}-${generatedBannerUID}`}
          label={label}
          placeholder={locationInputLabel}
          variant="filled"
          className={`app-search-field t-14-15 font-semibold ${icon && 'dark-field'}`}
          value={initialLocation}
          onChange={(event) => handleValueChange(event)}
        />
        {hasSubmitButton && (
          <Button
            variant="contained"
            color="primary"
            disableFocusRipple
            className="icon-only"
            sx={{
              position: 'absolute',
              right: '4px',
              paddingLeft: '15px',
              paddingRight: '15px'
            }}
            onClick={() => {
              onSelect(selectedLocation, true);
            }}
            endIcon={
              <Icon
                iconName="search"
                className="svg-icon icon-24 moveRightIcon flex items-center justify-center"
              ></Icon>
            }
            aria-label="Search"
          />
        )}
      </FormControl>
    </div>
  );
};

export default PlacesAutocomplete;
