/* eslint-disable-next-line */
'use client';

import { Container, Grid } from '@mui/material';
import 'swiper/css';
import 'swiper/css/pagination';
import ArticleTags, { ArticleTag } from '../../resources-components/tags';
import { CmsImageProps } from '@bayada/interfaces';
import { CmsRichTextProps } from 'apps/bayada/components/cms-rich-text/cms-rich-text';
import { ServiceProps } from '../../resources-components/services-list';
import { BioPageProps } from 'apps/bayada/components/bio-page/bio-page';
import ShareBlock, {
  ShareBlockProps
} from 'apps/bayada/components/share-block/share-block';
import DynamicCarousel from 'apps/bayada/components/dynamic-carousel/dynamic-carousel';
import { PageContent } from '../../resources-components/resources-page-content';
import { HubspotFormProps } from 'next-hubspot';
import { CmsHubSpotForm } from 'apps/bayada/components/hub-spot-form/cms-hub-spot-form';
import { useAppContext } from 'apps/bayada/app/context/app-context';
import { useEffect } from 'react';

// hubspot form to be integrated

export type NewsProps = {
  services: ServiceProps | null;
  title: string;
  overview?: string;
  thumbnailImage?: CmsImageProps | null;
  author: BioPageProps | null;
  date: string;
  image: CmsImageProps | null;
  shareBlock?: ShareBlockProps | null;
  tags?: ArticleTag[];
  contents: CmsRichTextProps;
  recommendedCarouselItemCount?: number;
  recommendedCarouselLabel?: string;
  aboutTheAuthorLabel?: string;
  tagsLabel?: string;
  readMoreButtonText?: string;
  readMoreAriaLabel?: string;
  readMoreIcon?: CmsImageProps | null;
  form?: HubspotFormProps;
  env: string;
};

/**
 * News page component is responsible for rendering the News page.
 * It takes in News props and uses them to render the page contents..
 */
export function News(props: NewsProps): JSX.Element {
  const { setIsProgressbarRequired } = useAppContext();

  useEffect(() => {
    setIsProgressbarRequired(true);
    return () => {
      setIsProgressbarRequired(false);
    };
  }, []);
  const {
    author,
    contents,
    date,
    image,
    services,
    shareBlock,
    title,
    recommendedCarouselItemCount,
    recommendedCarouselLabel = 'Read more',
    aboutTheAuthorLabel,
    tags,
    form
  } = props || {};

  return (
    <div className="my-10 flex flex-col w-full">
      <Container fixed className="relative mx-auto">
        <Grid container spacing={2}>
          <Grid item base={12} lg={9}>
            <PageContent
              author={author}
              contents={contents}
              date={date}
              image={image}
              title={title}
              carouselItemCount={recommendedCarouselItemCount}
              carouselLabel={recommendedCarouselLabel}
              shareBlock={shareBlock}
              aboutTheAuthorLabel={aboutTheAuthorLabel}
            />
          </Grid>
          <Grid item base={12} lg={3} className="relative">
            <div className="sticky top-0 grid">
              {shareBlock && (
                <div
                  className="hidden lg:block order-0 mt-0 lg:mt-8 xl:mt-10"
                  aria-label="share-container"
                >
                  <ShareBlock {...shareBlock} />
                </div>
              )}

              {form?.formId && (
                <div className="flex flex-col gap-4 order-2 lg:order-1 max-w-[29.125rem] lg:max-w-full mt-10 mb-4 lg:mb-6">
                  <h2
                    className="t-18-21  font-frutiger font-bold"
                    aria-label="subscribe-message"
                  >
                    Subscribe to our news blog
                  </h2>

                  <div>
                    <CmsHubSpotForm id={'newsForm'} formId={form?.formId} />
                  </div>
                </div>
              )}

              {tags && (
                <div className="border-b lg:border-0 border-solid border-ba-gray-200 pb-8 order-1 lg:order-2">
                  <ArticleTags tags={tags} />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>

      {recommendedCarouselLabel && (
        <Container fixed className="relative mx-auto mt-20">
          <h3
            className="t-18-21 mb-0 font-frutiger font-bold"
            aria-label="recommended-label"
          >
            {recommendedCarouselLabel}
          </h3>
        </Container>
      )}
      <DynamicCarousel
        env={props?.env}
        type={'news'}
        services={[services]}
        tags={tags}
        numberOfSlides={recommendedCarouselItemCount}
      />
    </div>
  );
}

export default News;
