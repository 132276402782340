import states from './States.json';

export interface OfficeLicense {
  officeId: string;
  licenseNumber: string;
}

export interface ServiceApiModel {
  code: string;
  description: string;
  Name: string;
}

export interface Director {
  FirstName: string;
  MiddleName?: string;
  LastName?: string;
}

export interface OfficeApiModel {
  OfficeId: number;
  latitude: number;
  longitude: number;
  specialties: ServiceApiModel[];
  code: string;
  description: string;
  name: string;
  number: number;
  phone: string;
  fax: string;
  tollfreephone: string;
  director: string;
  practice: string;
  county: string;
  countrycode: string;
  state: string;
  city: string;
  zipcode: string;
  country: string;
  address1: string;
  address2: string;
  proximityinmiles: number;
}

export interface OfficeModel {
  abbreviation: string;
  description: string;
  officeId: number;
  name: string;
  number: number;
  phone: string;
  fax: string;
  tollFreePhone: string | null;
  mailingAddress1: string;
  mailingAddress2: string;
  mailingAddressCity: string;
  mailingAddressState: string | null;
  mailingAddressZipCode: string;
  mailingAddressLatitude: number;
  mailingAddressLongitude: number;
  practice: string;
  services: string[];
  directorName: string;
  stateName: string | null;
  countryCode: string;
  distance: number;
  isJV: boolean;
  licenseNumber: string | null;
  serviceAreaCounty: string;
}

/* This method retrieves the state name using the state code  */
const getStateWithCode = (statecode: string) => {
  if (!states || !statecode) {
    return null;
  }
  const stateObj = states?.find(
    (state) => state?.code?.toLowerCase() === statecode?.toLowerCase()
  );
  return stateObj?.name as string;
};

/* This method retrieves the License Number  using OfficeID  */
const getLicenseNumber = (licenseData: OfficeLicense[], id: number) => {
  const data = licenseData?.find(
    (e: OfficeLicense) => e.officeId == id.toString()
  );
  return data?.licenseNumber || null;
};

const getFullName = (director: Director) => {
  const fullNameParts = [];

  if (director?.FirstName) {
    fullNameParts?.push(director?.FirstName);
  }

  if (director?.LastName) {
    fullNameParts?.push(director?.LastName);
  }

  const Name = fullNameParts?.join(' ');
  return Name;
};

/**
 * The function `mapOfficeAPI` maps officeAPI data to officeModel
 * @param {OfficeApiModel} [data] - The `data` parameter in the `mapOfficeAPI` accept specific office Data that is fetched from API
 * @param {OfficeLicense[]} [licenseData] - The `licenseData` parameter in the mapOfficeAPI accept a list of Licenses entries
 * @returns The function mapOfficeAPI returns the office page data mapped as required for the officeModel
 */

export function mapOfficeAPI(
  data: OfficeApiModel,
  licenseData?: OfficeLicense[]
): OfficeModel | null {
  // console.log(data, 'mapOfficeAPIdata');
  if (data) {
    /* Convert all keys to lowercase */
    const apiData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key.toLowerCase(), value])
    );

    if (
      !apiData?.specialties.length ||
      !apiData.latitude ||
      !apiData.longitude
    ) {
      return null;
    }

    const officeData: OfficeModel = {
      abbreviation: apiData?.code ?? '',
      description: apiData?.description ?? '',
      officeId: apiData?.officeid,
      name: apiData?.name ?? '',
      number: apiData?.number,
      phone: apiData?.phone,
      fax: apiData?.fax ?? '',
      tollFreePhone: apiData?.tollfreephone,
      mailingAddress1: apiData?.address1 ?? '',
      mailingAddress2: apiData?.address2 ?? '',
      mailingAddressState: apiData?.state ?? '',
      mailingAddressCity: apiData?.city ?? '',
      mailingAddressZipCode: apiData?.zipcode ?? '',
      mailingAddressLatitude: apiData?.latitude,
      mailingAddressLongitude: apiData?.longitude,
      practice: apiData?.practice,
      directorName: getFullName(apiData?.director),
      countryCode: apiData?.countrycode,
      distance: apiData?.proximityinmiles,
      services: apiData?.specialties?.map(
        (service: ServiceApiModel): string => {
          return service?.Name;
        }
      ),
      isJV: apiData?.code?.endsWith('J'),
      stateName: getStateWithCode(apiData?.state) ?? '',
      licenseNumber:
        getLicenseNumber(licenseData as OfficeLicense[], apiData?.officeid) ??
        '',
      serviceAreaCounty: apiData?.county?.replace(/,\s*/g, ' . ') ?? ''
    };
    // console.log(officeData, 'mapOfficeAPIdata parsed');

    return officeData;
  }
  return null;
}
