/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import React, { forwardRef, useContext, useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { OfficePageProps } from '../../../components/office-page/office-page';
import MarkerPopup from './marker-popup';
import { ServiceProps } from '../../../components/resources/resources-components/services-list';
import {
  findAnOfficePageProps,
  serviceDataProps
} from 'apps/bayada/app/find-an-office/page';
import { GoogleMapContext } from '../../context/google-map-context-provider';
import { getServiceArray } from '../office-service';
import { OfficeModel } from '../office-data-parser';

const standardMapStyle = [
  {
    elementType: 'all',
    stylers: [
      { saturation: -100 }, // Desaturate all elements
      { lightness: 30 }
    ]
  }
];

interface OfficeMapProps {
  localOfficePage?: OfficePageProps | null;
  serviceList?: ServiceProps[] | null;
  initialPlace?: any;
  offices?: OfficeModel[];
  officePageData?: findAnOfficePageProps;
  selectedService?: serviceDataProps;
  isFindAnOfficePage: boolean;
  isLocalOfficePage: boolean;
  isJVPage?: boolean;
  jvService?: ServiceProps | undefined;
  address?: string;
}

/**
 * Represents the component for displaying an office map.
 * @param {Object} props - The props for the OfficeMap component.
 * @param {LocalOfficePageProps} props.localOfficePage - The local office page details.
 * @param {SpecialityProps[]} props.serviceList - The list of services provided by the offices.
 */
const OfficeMap: React.FC<any> = forwardRef((props: OfficeMapProps, ref) => {
  const {
    localOfficePage,
    serviceList,
    initialPlace,
    isFindAnOfficePage,
    officePageData,
    offices,
    selectedService,
    isLocalOfficePage,
    isJVPage,
    jvService,
    address
  } = props || {};

  const { isLoaded } = useContext(GoogleMapContext);
  const [selectedPlace, setSelectedPlace] = useState<OfficeModel>();
  const [center, setCenter] = useState();
  const mapRef = useRef<google.maps.Map | null>(null);
  React.useImperativeHandle(ref, () => ({
    setCenterOnSelection: setCenterOnSelection,
    handleInfoWindowClose: handleInfoWindowClose
  }));

  const handleInfoWindowClose = () => {
    markerPopupRef?.current?.handleInfoWindowClose();
  };
  const setCenterOnSelection = (selectedLocation: any) => {
    if (isFindAnOfficePage && selectedLocation?.lat && selectedLocation?.lng) {
      setCenter(selectedLocation);
    }
  };

  const mapStyles = isFindAnOfficePage
    ? {
        height: '100%',
        width: '100%'
      }
    : {
        height: '55vh',
        width: '100%'
      };

  const boxStyles = isFindAnOfficePage
    ? { width: '100%', height: '100%' }
    : { width: '100%', height: '60%' };

  const locationMarkerColor = 'var(--ba-blue-600)'; //temporary color for JV
  const markerColor = isJVPage
    ? locationMarkerColor.replace('#', '%23')
    : '%23ce0e2d';

  useEffect(() => {
    if (isLoaded) {
      if (!initialPlace) {
        setCurrentLocation();
      }
      if (offices?.length && isFindAnOfficePage) {
        const bounds = new google.maps.LatLngBounds();
        offices?.forEach((obj: any) =>
          bounds.extend({
            lat: obj?.mailingAddressLatitude,
            lng: obj?.mailingAddressLongitude
          })
        );
        if (
          mapRef?.current &&
          window.google &&
          bounds instanceof window.google.maps.LatLngBounds
        ) {
          mapRef?.current?.fitBounds(bounds);
          const zoom = mapRef?.current.getZoom();
          if (zoom && offices?.length === 1) {
            mapRef?.current?.setZoom(10);
          }
        }
      }
    }
  }, [isLoaded, props]);

  /**
   * Calculates the center point of the map based on the average latitude and longitude of the office locations.
   * @returns {{ lat: number, lng: number }} The latitude and longitude coordinates of the center point.
   */
  const getMapCenter = () => {
    if (offices) {
      let meanLatitude = offices[0]?.mailingAddressLatitude;
      let meanLongitude = offices[0]?.mailingAddressLongitude;
      let latSum = 0;
      let lngSum = 0;

      offices?.map(
        (office: OfficeModel) => (
          (latSum += office?.mailingAddressLatitude),
          (lngSum += office?.mailingAddressLongitude)
        )
      );

      if (latSum != 0) {
        meanLatitude = latSum / offices?.length;
      }
      if (lngSum != 0) {
        meanLongitude = lngSum / offices?.length;
      }

      return {
        lat: meanLatitude,
        lng: meanLongitude
      };
    }
  };

  /**
   * Sets the map center to the current user's location using geolocation.
   * If geolocation is supported by the browser, it retrieves the current position and updates the map center accordingly.
   */
  const setCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position && !address) {
            const { latitude, longitude } = position.coords;
            mapRef.current?.setCenter({ lat: latitude, lng: longitude });
          }
        },
        (error) => {
          mapRef?.current?.setCenter({ lat: 40.73061, lng: -73.935242 });
          mapRef?.current?.setZoom(2);
        }
      );
    }
  };

  /**
   * Calculates the default center point for a specific office on the map.
   * @param {OfficeDetailsProps} office The office details containing latitude and longitude.
   * @returns {{ lat: number, lng: number }} The latitude and longitude coordinates of the default center point.
   */
  const getDefaultCenter = (office: OfficeModel) => {
    return {
      lat: office?.mailingAddressLatitude,
      lng: office?.mailingAddressLongitude
    };
  };

  const onLoad = (map: google.maps.Map) => {
    if (isFindAnOfficePage && map) {
      map.setOptions({ streetViewControl: false });
      mapRef.current = map;
    }
  };

  const markerPopupRef = useRef<any>();

  /**
   * Handles the click event on a marker representing an office on the map.
   * Updates the selected place state with the clicked office details.
   * @param {OfficeDetailsProps} office The office details associated with the clicked marker.
   */
  const handleMarkerClick = (office: OfficeModel) => {
    setSelectedPlace(office);
  };

  const getBounds = () => {
    let minLat = offices?.[0]?.mailingAddressLatitude ?? 0;
    let maxLat = offices?.[0]?.mailingAddressLatitude ?? 0;
    let minLng = offices?.[0]?.mailingAddressLongitude ?? 0;
    let maxLng = offices?.[0]?.mailingAddressLongitude ?? 0;

    offices?.forEach((point) => {
      if (minLat && point.mailingAddressLatitude < minLat)
        minLat = point.mailingAddressLatitude;
      if (maxLat && point.mailingAddressLatitude > maxLat)
        maxLat = point.mailingAddressLatitude;
      if (minLng && point.mailingAddressLongitude < minLng)
        minLng = point.mailingAddressLongitude;
      if (maxLng && point.mailingAddressLongitude > maxLng)
        maxLng = point.mailingAddressLongitude;
    });

    return {
      north: maxLat,
      south: minLat,
      east: maxLng,
      west: minLng
    };
  };

  return (
    <Box sx={boxStyles}>
      {isLoaded && isFindAnOfficePage && (
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={center}
          zoom={10}
          onLoad={onLoad}
          options={{
            styles: standardMapStyle,
            mapTypeControl: false
          }}
        >
          {offices?.map((obj: any, indx: number) => {
            const servArr: any[] = getServiceArray(
              obj,
              officePageData?.services
            );
            obj.serviceArr = servArr;
            return (
              <Marker
                key={obj?.id + '-' + indx}
                title={obj?.name}
                position={{
                  lat: obj?.mailingAddressLatitude,
                  lng: obj.mailingAddressLongitude
                }}
                animation={google.maps.Animation.DROP}
                onClick={() => handleMarkerClick(obj)}
                aria-label={`Marker ${indx}`}
                icon={{
                  url: `data:image/svg+xml;charset=UTF-8,
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                    <path fill='%23ce0e2d' stroke='%23fff' stroke-width='1' d='M12 2C8.13 2 5 5.13 5 9c0 7 7 13 7 13s7-6 7-13c0-3.87-3.13-7-7-7zm0 10c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z'/>
                </svg>`,
                  scaledSize: new window.google.maps.Size(50, 50) // Size of the marker
                }}
              />
            );
          })}
          {selectedPlace && (
            <MarkerPopup
              office={selectedPlace}
              setSelectedPlace={setSelectedPlace}
              localOfficePage={
                isFindAnOfficePage ? officePageData : localOfficePage
              }
              serviceList={serviceList}
              isFindAnOfficePage={isFindAnOfficePage}
              selectedService={selectedService}
              ref={markerPopupRef}
            />
          )}
        </GoogleMap>
      )}
      {isLoaded && isLocalOfficePage && (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={10}
          center={getMapCenter()}
          options={{
            styles: standardMapStyle,
            streetViewControl: false,
            mapTypeControl: false
          }}
          onLoad={(map) => {
            const bounds = new window.google.maps.LatLngBounds(getBounds());
            map.fitBounds(bounds);
            google.maps.event.addListenerOnce(
              map,
              'bounds_changed',
              function () {
                const zoom = map.getZoom();
                if (zoom && offices?.length === 1) {
                  map.setZoom(10);
                }
              }
            );
          }}
        >
          {selectedPlace && (
            <MarkerPopup
              office={selectedPlace}
              setSelectedPlace={setSelectedPlace}
              localOfficePage={
                isFindAnOfficePage ? officePageData : localOfficePage
              }
              serviceList={serviceList}
              isFindAnOfficePage={isFindAnOfficePage}
              selectedService={selectedService}
              ref={markerPopupRef}
              jvService={jvService}
              isJv={isJVPage}
            />
          )}
          {offices?.map((office: OfficeModel, index: number) => (
            <Marker
              key={index}
              title={office?.name}
              position={getDefaultCenter(office)}
              onClick={() => handleMarkerClick(office)}
              aria-label={`Marker ${index}`}
              animation={google.maps.Animation.DROP}
              icon={{
                url: `data:image/svg+xml;charset=UTF-8,
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                  <path fill='${markerColor}' stroke='%23fff' stroke-width='1' d='M12 2C8.13 2 5 5.13 5 9c0 7 7 13 7 13s7-6 7-13c0-3.87-3.13-7-7-7zm0 10c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z'/>
              </svg>`,
                scaledSize: new window.google.maps.Size(50, 50) // Size of the marker
              }}
            />
          ))}
        </GoogleMap>
      )}
    </Box>
  );
});
OfficeMap.displayName = 'OfficeMap';

export default OfficeMap;
