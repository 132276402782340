/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

export const SCROLL_UP = 'up';
export const SCROLL_DOWN = 'down';

/*
It takes an options object with initialDirection (default is SCROLL_DOWN) and thresholdPixels (default is 64).
scrollDir state is used to store the current scroll direction (SCROLL_UP or SCROLL_DOWN).
scrollY state is used to store the current scroll position.
useEffect is used to add an event listener to the window's scroll event.
The event listener uses the requestAnimationFrame method to optimize performance by updating the scroll direction only when the browser is ready to repaint.
The onScroll function checks if the scroll difference exceeds the threshold and updates the scrollDir and scrollY accordingly.
The hook returns an object containing the current scrollDirection and scrollY.

*/

const useScrollDirection = ({
  initialDirection = SCROLL_UP,
  thresholdPixels = 64,
  debounceDelay = 100
} = {}) => {
  const [scrollDir, setScrollDir] = useState(initialDirection);
  const [scrollY, setScrollY] = useState<number>();

  useEffect(() => {
    const threshold = thresholdPixels || 0;
    let lastScrollY = window.scrollY;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.scrollY;
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        // We haven't exceeded the threshold
        ticking = false;

        return;
      }

      setScrollDir(scrollY > lastScrollY ? SCROLL_DOWN : SCROLL_UP);
      setScrollY(scrollY);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const debouncedScroll = debounce(updateScrollDir, debounceDelay);
    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(debouncedScroll);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [debounceDelay, initialDirection, thresholdPixels]);

  const debounce = (func: any, delay: number) => {
    let timer: string | number | NodeJS.Timeout;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  return { scrollDirection: scrollDir, scrollY: scrollY ?? 0 };
};

export default useScrollDirection;
