import { Libraries, useJsApiLoader } from '@react-google-maps/api';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { GoogleMapApiKey } from 'apps/bayada/constants';
import React from 'react';
import { ReactNode, createContext } from 'react';

export interface IDefaultProvider {
  isLoaded: boolean;
  loadError: Error | undefined;
}
const defaultProvider: IDefaultProvider = {
  isLoaded: false,
  loadError: undefined
};

const GoogleMapContext = createContext(defaultProvider);
type Props = {
  children: ReactNode;
};
const libraries: Libraries = ['places', 'maps', 'geocoding'];

/**
 * Component that wraps all Google Map components and provides
 * a Google Map context to all components that need it.
 */
const GoogleMapContextProvider: React.FC<Props> = ({ children }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GoogleMapApiKey ?? '',
    libraries: libraries
  });

  const values = {
    isLoaded,
    loadError
  };

  return (
    <GoogleMapContext.Provider value={values}>
      {children}
    </GoogleMapContext.Provider>
  );
};

export { GoogleMapContext, GoogleMapContextProvider };
