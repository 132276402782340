'use client';
import * as React from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Icon } from '@bayada/shared/ui-components';
import NavigationLink, {
  NavigationLinkProps
} from '../navigation-link/navigation-link';
import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import debounce from 'lodash/debounce';
import { MarkDown } from '@bayada/shared/ui-components';
import { useAppContext } from 'apps/bayada/app/context/app-context';

interface menuListType {
  triggerClassName?: string;
  textClassName?: string;
  buttonIcon?: React.ReactNode;
  iconStart?: boolean;
  item?: NavigationLinkProps;
  drawerItem?: boolean;
}
/**
 * The `MenuListComposition` function in TypeScript React is a component that renders a button with a
 * dropdown menu using Material-UI components.
 * @param event - The `event` parameter in the `handleClose` function and `handleCloseOnScrollOutside`
 * function represents the event that triggered the function. It can be either a standard JavaScript
 * `Event` or a React `SyntheticEvent`. This parameter is used to check if the target element of the
 * event
 */
const MenuListComposition: React.FC<menuListType> = ({
  triggerClassName,
  buttonIcon,
  iconStart,
  item
}) => {
  const route = useRouter();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const prevOpen = React.useRef(open);
  const { context } = useAppContext();
  const { userAgent } = context;

  const handleToggle = debounce(() => {
    setOpen((prevOpen) => !prevOpen);
  }, 300);

  const handleClose = (
    event: Event | React.SyntheticEvent,
    value?: NavigationLinkProps
  ) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
    if (value?.url && window) {
      const _target = value?.target === 'newtab' ? '_blank' : '_self';
      if (userAgent === 'Firefox' || _target === '_blank') {
        window.open(value?.url, _target);
      } else {
        route.push(value?.url);
      }
    }
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleCloseOnScrollOutside(event: any) {
      if (anchorRef?.current && !anchorRef?.current?.contains(event?.target)) {
        setOpen(false);
      }
    }
    window.addEventListener('scroll', handleCloseOnScrollOutside);
    return () => {
      window.removeEventListener('scroll', handleCloseOnScrollOutside);
    };
  }, []);

  return (
    <div className="flex gap-3">
      <Button
        variant="text"
        color="secondary"
        disableFocusRipple
        ref={anchorRef}
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={`menu-dropdown ${triggerClassName}`}
        startIcon={iconStart ? buttonIcon : null}
        endIcon={
          <Icon
            iconName="chevronDownThin"
            className="svg-icon icon-12 flex items-center justify-center "
          ></Icon>
        }
        aria-label="primary-button"
      >
        {item?.text && (
          <span
            dangerouslySetInnerHTML={{ __html: MarkDown(item?.text) }}
            className="t-13 font-frutiger set-frutiger font-bold normal-case leading-[1]"
          />
        )}
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            timeout={0}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  className="app-menu-list"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  data-lenis-prevent
                >
                  {item?.dropdown?.map((value: NavigationLinkProps) => (
                    <MenuItem
                      key={value.text}
                      tabIndex={-1}
                      onClick={(e) => handleClose(e, value)}
                      className="app-menu-list-items"
                    >
                      {/* {value.text} */}
                      <NavigationLink
                        {...value}
                        isDrawerDropdownLink={true}
                        className="t-16-17 font-inter leading-none no-underline w-full text-left inline-flex"
                        textClass="t-14-15 font-inter leading-6"
                      />
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default MenuListComposition;
