/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import { CmsImage, Icon } from '@bayada/shared/ui-components';
import { memo, useCallback } from 'react';
import { CmsImageProps } from '@bayada/interfaces';
import { MarkDown } from '@bayada/shared/ui-components';
import { useAppContext } from 'apps/bayada/app/context/app-context';
import Link from 'next/link';
export type NavigationLinkProps = {
  text: string;
  url: string;
  target: string;
  ariaLabel: string;
  icon: CmsImageProps | null;
  iconPosition: string;
  className?: string;
  sysId?: string;
  dropdown?: NavigationLinkProps[];
  type?: string;
  isDrawerDropdownLink?: boolean;
  tabIndex?: number;
  textClass?: string;
};

/**
 * The code defines a React component called NavigationLink that renders a navigation link with
 * optional icon and dropdown menu functionality.
 * @property {string} text - The `text` property in the `NavigationLinkProps` type represents the text
 * content that will be displayed for the navigation link. It is a required property and should be a
 * string value.
 * @property {string} url - The `url` property in the `NavigationLinkProps` type represents the URL
 * that the navigation link should navigate to when clicked. It is a string type that specifies the
 * destination URL for the link.
 * @property {string} target - The `target` property in the `NavigationLinkProps` type is used to
 * specify the target attribute for the link. It determines where to open the linked document when the
 * link is clicked. The possible values for the `target` property are:
 * @property {string} ariaLabel - The `ariaLabel` property in the `NavigationLinkProps` type is used to
 * provide an accessible label for the navigation link. It is a string that describes the purpose of
 * the navigation link for users who rely on screen readers or other assistive technologies. In the
 * code snippet you provided, the default
 * @property {CmsImageProps | null} icon - The `icon` property in the `NavigationLinkProps` type is
 * used to specify the icon for the navigation link. It accepts a `CmsImageProps` object or `null`. The
 * icon is displayed based on the conditions specified in the `NavigationLink` component. If the `type`
 * @property {string} iconPosition - The `iconPosition` property in the `NavigationLinkProps` type
 * determines the position of the icon relative to the text in the navigation link component. It can
 * have two possible values:
 * @property {string} className - The `className` property in the `NavigationLinkProps` type is used to
 * specify additional CSS classes that can be applied to the navigation link component. It allows for
 * custom styling and layout adjustments based on the specific requirements of the project or design.
 * @property {string} sysId - The `sysId` property in the `NavigationLinkProps` type is used to store a
 * unique identifier for the navigation link. This identifier can be used for various purposes such as
 * tracking, identifying specific links, or any other functionality that requires a unique identifier
 * for each navigation link.
 * @property {NavigationLinkProps[]} dropdown - The `dropdown` property in the `NavigationLinkProps`
 * type is an array of `NavigationLinkProps` objects. It allows you to create a hierarchical navigation
 * structure where a navigation link can have sub-navigation links (dropdown items).
 * @property {string} type - The `type` property in the `NavigationLinkProps` type is used to specify
 * the type of navigation link. It can have values like `'menuitem'` or any other custom type you
 * define. This property helps in determining the styling or behavior of the navigation link based on
 * its type.
 */

export const NavigationLink: React.FC<NavigationLinkProps> = memo(
  (props: NavigationLinkProps) => {
    const {
      text,
      url,
      target,
      ariaLabel = 'navigation-link',
      className,
      type,
      icon,
      iconPosition = 'before',
      isDrawerDropdownLink = false,
      textClass
    } = props || {};
    const { context } = useAppContext();
    const { userAgent } = context || {};
    const IconComponent = useCallback(() => {
      return (
        <span
          className={`menu-icon inline-flex empty:hidden font-frutiger shrink-0 ${iconPosition === 'before' ? 'mr-1' : 'ml-2'}`}
          aria-label="nav-link-icon"
          role="img"
        >
          {icon && type == 'menuitem' ? (
            <CmsImage
              {...icon}
              width={20}
              height={20}
              className="object-contain"
            />
          ) : null}
        </span>
      );
    }, [icon, iconPosition, type]);
    return (
      <Link
        aria-label={ariaLabel}
        href={url}
        className={`${className ?? ''} ${type === 'menuitem' ? 'inline-flex items-center' : isDrawerDropdownLink ? '' : 'flex grow flex-row items-start justify-between border-b border-solid border-ba-gray-100 py-4 w-full section-nav break-words'}`}
        target={target !== 'sametab' ? '_blank' : '_self'}
        prefetch={false}
        passHref
        onClick={(e) => {
          if (userAgent === 'Firefox') {
            e?.preventDefault();
            window.open(url, target !== 'sametab' ? '_blank' : '_self');
          }
        }}
      >
        {iconPosition === 'before' && <IconComponent />}
        <span
          dangerouslySetInnerHTML={{ __html: MarkDown(text) }}
          className={`${textClass} nav-text min-w-0`}
        />
        {iconPosition === 'after' && !isDrawerDropdownLink && <IconComponent />}
        {type !== 'menuitem' && !isDrawerDropdownLink && (
          <Icon
            iconName="chevronRight"
            className="svg-icon icon-24 flex items-center justify-center shrink-0 mt-1"
          />
        )}
      </Link>
    );
  }
);
NavigationLink.displayName = 'NavigationLink';
export default NavigationLink;
